import { CURRENTDATE } from '../app-constants';

export const InventoryDynamicJSON = [
  //add new plasmid
  {
    jsonId: 2,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [
          {
            type: 'dropdown',
            showBlankInPlaceholder: true,
            blankDataList: ['""', 'undefined', 'null'],
            name: 'plasmid_type',
            id: 'plasmidType',
            validationButtonType: 'submit',
            classname: '',
            required: true,
            apiEndPoints: [
              {
                type: 'init',
                method: 'post',
                url: 'inventory-plasmids/inventory-sub-types',
                payload: {
                  perma: ['plasmids'],
                },
              },
            ],
            options: [],
            multiple: false,
            isClearable: false,
            isStatus: false,
            search: false,
            singleSelect: true,
            showLabel: 'value',
            errorMessage: { noData: 'This field is required' },
            requiredErrorMessage: 'This field is required',
            value: '',
            imageUrl: '',
            disabled: false,
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Plasmid Type',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_935',
        classname: 'form-group',
        wrapperType: 'BOX',
        id: 'wrapper_276',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [
          {
            type: 'text',
            name: 'plasmid',
            id: 'plasmid',
            value: '',
            placeholder: '',
            multiline: false,
            classname: 'form-control ',
            regularExpression: '',
            disabled: false,
            required: true,
            errorMessage: { noData: 'This field is required', regexFail: '' },
            requiredErrorMessage: 'This field is required',
            validationButtonType: 'submit',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Plasmid Name',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_584',
        classname: 'form-group',
        wrapperType: 'BOX',
        id: 'wrapper_299',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [
          {
            type: 'text',
            name: 'description',
            id: 'description',
            value: '',
            placeholder: '',
            multiline: true,
            classname: 'form-control textarea',
            regularExpression: '',
            disabled: false,
            required: true,
            errorMessage: { noData: 'This field is required', regexFail: '' },
            requiredErrorMessage: 'This field is required',
            validationButtonType: 'submit',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Description',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_946',
        classname: 'form-group',
        wrapperType: 'BOX',
        id: 'wrapper_547',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  //Add new inventory Plasmid
  {
    jsonId: 3,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_529',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_10',
        fields: [
          {
            control: [
              {
                type: 'radio',
                name: 'source',
                label: {
                  type: 'label',
                  name: '',
                  classname: '',
                  for: '',
                  required: false,
                  wrapperClassName: '',
                },
                id: 'source',
                value: {
                  id: 1,
                  value: 'In House',
                },
                required: true,
                classname: 'form-check-wrap form-source',
                errorMessage: {
                  noData: '',
                },
                options: [
                  {
                    key: {
                      id: 1,
                      value: 'In House',
                    },
                    label: 'In House',
                  },
                  {
                    key: {
                      id: 2,
                      value: 'Commercial',
                    },
                    label: 'Commercial',
                  },
                  {
                    key: {
                      id: 3,
                      value: 'Collaborator',
                    },
                    label: 'Collaborator',
                  },
                ],
                functionName: '',
                readonly: false,
                validationButtonType: 'submit',
                disabled: false,
                wrapperClassName: 'form-check form-check-inline',
                showInputLabel: true,
                labelContent: 'Source',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
                controlPoints: [
                  {
                    type: 'change',
                    value: {
                      id: 1,
                      value: 'In House',
                    },
                    Mapto: [
                      {
                        id: 'main.wrapper_171',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_260.wrapper_582',
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_260.wrapper_857',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_260.wrapper_31',
                        type: 'hideField',
                      },
                    ],
                  },
                  {
                    type: 'change',
                    value: {
                      id: 2,
                      value: 'Commercial',
                    },
                    Mapto: [
                      {
                        id: 'main.wrapper_171',
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_260.wrapper_857',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_260.wrapper_582',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_260.wrapper_31',
                        type: 'hideField',
                      },
                    ],
                  },
                  {
                    type: 'change',
                    value: {
                      id: 3,
                      value: 'Collaborator',
                    },
                    Mapto: [
                      {
                        id: 'main.wrapper_171',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_260.wrapper_857',
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_260.wrapper_31',
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_260.wrapper_582',
                        type: 'hideField',
                      },
                    ],
                  },
                ],
              },
            ],
            type: '',
            name: 'wrapper_273',
            classname: 'col-12 text-center',
            wrapperType: 'BOX',
            id: 'wrapper_677',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_753',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_260',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'plasmid_name',
                id: 'plasmid_name',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                requiredErrorMessage: 'This field is required',
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Plasmid Name',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_860',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_836',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'plasmids_id',
                id: 'plasmids_id',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: true,
                required: true,
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Plasmid ID',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
                readonly: true,
              },
            ],
            type: '',
            name: 'wrapper_147',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_904',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'in_house_id',
                id: 'in_house_id',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'In House Source ID',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_525',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_582',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
            disableValue: {
              in_house_id: null,
            },
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'collaborator',
                id: 'collaborator',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['collab'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Collaborator Name',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_750',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_857',
            fields: [],
            head: '',
            tabHeadClass: '',
            disableValue: {
              collaborator: {
                id: 0,
                value: '',
              },
            },
          },
          {
            control: [
              {
                type: 'text',
                name: 'collaborator_source_id',
                id: 'collaborator_source_id',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Collaborator Source Id',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_707',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_31',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
            disableValue: {
              collaborator_source_id: null,
            },
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_922',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_171',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'vendor',
                id: 'vendor',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['vendor'],
                      inv_type_perma: 'plasmids',
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Vendor',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_750',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_857',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'catalogue_no',
                id: 'catalogue_no',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                requiredErrorMessage: 'This field is required',
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Catalogue No',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_782',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_475',
            fields: [],
            head: '',
            tabHeadClass: '',
            disableValue: {
              catalogueNo: null,
            },
          },
          {
            control: [
              {
                type: 'text',
                name: 'product_url',
                id: 'product_url',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                requiredErrorMessage: 'This field is required',
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Product URL',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_867',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_215',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
        disableValue: {
          vendor: {
            id: 0,
            value: '',
          },
          catalogueNo: null,
          productUrl: null,
        },
      },
      {
        control: [],
        type: '',
        name: 'wrapper_208',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_778',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'format',
                id: 'format',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['samp_form'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Format',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_364',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_955',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'total_no_of_tubes',
                id: 'total_no_of_tubes',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                requiredErrorMessage: 'Please select total no: of tubes',
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [
                  {
                    regularExpression: '/^[1-9][0-9]*$/',
                    errorMessage: 'Please type a valid non zero integer',
                  },
                ],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Total No. of Tubes',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_811',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_136',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [],
            type: '',
            name: 'wrapper_632',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_157',
            fields: [
              {
                control: [],
                type: '',
                name: 'wrapper_520',
                classname: 'row',
                wrapperType: 'BOX',
                id: 'wrapper_828',
                fields: [
                  {
                    control: [
                      {
                        type: 'text',
                        name: 'concentration',
                        id: 'concentration',
                        value: '',
                        placeholder: '',
                        multiline: false,
                        classname: 'form-control ',
                        regularExpression: '',
                        disabled: false,
                        required: false,
                        errorMessage: {
                          noData: 'This field is required',
                          regexFail: '',
                        },
                        validationButtonType: 'submit',
                        rows: 0,
                        columns: 0,
                        onDemandError: true,
                        customValidation: [
                          {
                            regularExpression: '^(0.[1-9]+|[1-9]d*(.d+)?)$',
                            errorMessage: 'Please type non zero number',
                          },
                        ],
                        multiError: false,
                        applicationError: '',
                        wrapperClassName: '',
                        showInputLabel: true,
                        labelContent: 'Conc (µg/ µl)',
                        labelClass: 'form-label',
                        isLabelAfter: false,
                        showAdditionalInputContent: false,
                        additionalInputContent: '',
                        isAdditonalInputAfter: true,
                        draggable: false,
                      },
                    ],
                    type: '',
                    name: 'wrapper_233',
                    classname: 'col-3',
                    wrapperType: 'BOX',
                    id: 'wrapper_329',
                    fields: [],
                    head: '',
                    tabHeadClass: '',
                  },

                  {
                    control: [
                      {
                        type: 'text',
                        name: 'volume',
                        id: 'volume',
                        value: '',
                        placeholder: '',
                        multiline: false,
                        classname: 'form-control ',
                        regularExpression: '',
                        disabled: false,
                        required: false,
                        errorMessage: {
                          noData: 'This field is required',
                          regexFail: '',
                        },
                        validationButtonType: 'submit',
                        rows: 0,
                        columns: 0,
                        onDemandError: true,
                        customValidation: [
                          {
                            regularExpression: '^(0.[1-9]+|[1-9]d*(.d+)?)$',
                            errorMessage: 'Please type non zero number',
                          },
                        ],
                        multiError: false,
                        applicationError: '',
                        wrapperClassName: '',
                        showInputLabel: true,
                        labelContent: 'Vol (µI)',
                        labelClass: 'form-label',
                        isLabelAfter: false,
                        showAdditionalInputContent: false,
                        additionalInputContent: '',
                        isAdditonalInputAfter: true,
                        draggable: false,
                      },
                    ],
                    type: '',
                    name: 'wrapper_622',
                    classname: 'col-3',
                    wrapperType: 'BOX',
                    id: 'wrapper_606',
                    fields: [],
                    head: '',
                    tabHeadClass: '',
                  },
                  {
                    control: [
                      {
                        type: 'text',
                        name: 'quantity_per_tube',
                        id: 'quantity_per_tube',
                        value: '',
                        placeholder: '',
                        multiline: false,
                        classname: 'form-control ',
                        regularExpression: '',
                        disabled: true,
                        required: false,
                        errorMessage: {
                          noData: 'This field is required',
                          regexFail: '',
                        },
                        validationButtonType: 'submit',
                        rows: 0,
                        columns: 0,
                        onDemandError: true,
                        customValidation: [
                          {
                            regularExpression: '^(0.[1-9]+|[1-9]d*(.d+)?)$',
                            errorMessage: 'Please type a valid non zero number',
                          },
                        ],
                        multiError: false,
                        applicationError: '',
                        wrapperClassName: '',
                        showInputLabel: true,
                        labelContent: 'Quantity Per Tube (µg)',
                        labelClass: 'form-label',
                        isLabelAfter: false,
                        showAdditionalInputContent: false,
                        additionalInputContent: '',
                        isAdditonalInputAfter: true,
                        draggable: false,
                      },
                    ],
                    type: '',
                    name: 'wrapper_738',
                    classname: 'col-6',
                    wrapperType: 'BOX',
                    id: 'wrapper_404',
                    fields: [],
                    head: '',
                    tabHeadClass: '',
                  },
                ],
                head: '',
                tabHeadClass: '',
                draggable: false,
              },
            ],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_303',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_598',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'biosafety_level',
                id: 'biosafety_level',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['bio_level'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: {
                  id: 2,
                  value: 'BSL-2',
                },
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Biosafety Level',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_967',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_216',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'bacterial_resistance',
                id: 'bacterial_resistance',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['bact_rest'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Bacterial Resistance',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_334',
            classname: 'col-2',
            wrapperType: 'BOX',
            id: 'wrapper_585',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'mammalian_resistance',
                id: 'mammalian_resistance',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['mam_rest'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Mammalian Resistance',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_487',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_57',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'radio',
                name: 'is_expire',
                label: {
                  type: 'label',
                  name: '',
                  classname: '',
                  for: '',
                  required: false,
                  wrapperClassName: '',
                },
                id: 'is_expire',
                value: 1,
                required: false,
                classname: 'form-check-wrap',
                errorMessage: {
                  noData: '',
                },
                options: [
                  {
                    key: 1,
                    label: 'Yes',
                  },
                  {
                    key: 0,
                    label: 'No',
                  },
                ],
                functionName: '',
                readonly: false,
                validationButtonType: 'submit',
                disabled: false,
                wrapperClassName: 'form-check form-check-inline',
                showInputLabel: true,
                labelContent: 'Expiry Date',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
                controlPoints: [
                  {
                    type: 'change',
                    value: 1,
                    Mapto: [
                      {
                        id: 'main.wrapper_598.wrapper_959',
                        type: 'showField',
                      },
                    ],
                  },
                  {
                    type: 'change',
                    value: 0,
                    Mapto: [
                      {
                        id: 'main.wrapper_598.wrapper_959',
                        type: 'hideField',
                      },
                    ],
                  },
                ],
              },
            ],
            type: '',
            name: 'wrapper_156',
            classname: 'col-2 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_358',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'expiry_date',
                id: 'expiry_date',
                validationButtonType: 'submit',
                readonly: false,
                range: false,
                value: 'CURRENTDATE',
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: 'CURRENTDATE',
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                required: true,
                classname: '',
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: 'Date is not valid!!',
                },
                errorClass: 'errorMessage',
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-GB',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: '',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_763',
            classname: 'col-2 date-no-label',
            wrapperType: 'BOX',
            id: 'wrapper_959',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
            disableValue: {
              expiry_date: '',
            },
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'storage_temp',
                id: 'storage_temp',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['st_temp'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Storage Temp (°C)',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_290',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_654',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'received_date',
                id: 'received_date',
                validationButtonType: 'submit',
                readonly: false,
                range: false,
                value: '',
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: '',
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                required: false,
                classname: '',
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: 'Date is not valid!!',
                },
                errorClass: 'errorMessage',
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-GB',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: 'Received Date',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_7635',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_9596',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
            disableValue: {
              expiry_date: '',
            },
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'date_of_storage',
                id: 'date_of_storage',
                validationButtonType: 'submit',
                readonly: false,
                range: false,
                value: 'CURRENTDATE',
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: 'CURRENTDATE',
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                required: true,
                classname: '',
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: 'Date is not valid!!',
                },
                errorClass: 'errorMessage',
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-GB',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: 'Date of Storage',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_7633',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_95989',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
            disableValue: {
              expiry_date: '',
            },
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
    showClass: true,
  },

  //Add new Cell Line
  {
    jsonId: 4,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [
          {
            type: 'text',
            name: 'celllines_name',
            id: 'celllineName',
            value: '',
            placeholder: '',
            multiline: false,
            classname: 'form-control ',
            regularExpression: '',
            disabled: false,
            required: true,
            requiredErrorMessage: 'This field is required',
            errorMessage: { noData: 'This field is required', regexFail: '' },
            validationButtonType: 'submit',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Cell Line Name',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_584',
        classname: 'form-group',
        wrapperType: 'BOX',
        id: 'wrapper_299',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [
          {
            type: 'text',
            name: 'celllines_desc',
            id: 'cellDescription',
            value: '',
            placeholder: '',
            multiline: true,
            classname: 'form-control textarea',
            regularExpression: '',
            disabled: false,
            required: true,
            errorMessage: { noData: 'This field is required', regexFail: '' },
            requiredErrorMessage: 'This field is required',
            validationButtonType: 'submit',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Description',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_946',
        classname: 'form-group',
        wrapperType: 'BOX',
        id: 'wrapper_547',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  //Add new inventory  Cell Line
  {
    control: [],
    jsonId: 5,
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_529',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_10',
        fields: [
          {
            control: [
              {
                type: 'radio',
                name: 'source',
                label: {
                  type: 'label',
                  name: '',
                  classname: '',
                  for: '',
                  required: false,
                  wrapperClassName: '',
                },
                id: 'source',
                value: {
                  id: 4,
                  value: 'In House Expanded',
                },
                required: true,
                classname: 'form-check-wrap form-source',
                errorMessage: {
                  noData: '',
                },
                options: [
                  {
                    key: {
                      id: 4,
                      value: 'In House Expanded',
                    },
                    label: 'In House Expanded Lines',
                  },
                  {
                    key: {
                      id: 5,
                      value: 'In House Created',
                    },
                    label: 'In House Created Lines',
                  },
                  {
                    key: {
                      id: 2,
                      value: 'Commercial',
                    },
                    label: 'Commercial',
                  },
                  {
                    key: {
                      id: 3,
                      value: 'Collaborator',
                    },
                    label: 'Collaborator',
                  },
                ],
                functionName: '',
                readonly: false,
                validationButtonType: 'submit',
                disabled: false,
                wrapperClassName: 'form-check form-check-inline',
                showInputLabel: true,
                labelContent: 'Source',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
                controlPoints: [
                  {
                    type: 'change',
                    value: {
                      id: 4,
                      value: 'In House Expanded',
                    },
                    Mapto: [
                      {
                        id: 'main.wrapper_171.wrapper_219',
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_171.wrapper_857',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_171.wrapper_475',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_171.wrapper_215',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_171.wrapper_256',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_171.wrapper_136',
                        type: 'hideField',
                      },
                    ],
                  },
                  {
                    type: 'change',
                    value: {
                      id: 5,
                      value: 'In House Created',
                    },
                    Mapto: [
                      {
                        id: 'main.wrapper_171.wrapper_219',
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_171.wrapper_857',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_171.wrapper_475',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_171.wrapper_215',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_171.wrapper_256',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_171.wrapper_136',
                        type: 'hideField',
                      },
                    ],
                  },
                  {
                    type: 'change',
                    value: {
                      id: 2,
                      value: 'Commercial',
                    },
                    Mapto: [
                      {
                        id: 'main.wrapper_171.wrapper_219',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_171.wrapper_857',
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_171.wrapper_475',
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_171.wrapper_215',
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_171.wrapper_256',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_171.wrapper_136',
                        type: 'hideField',
                      },
                    ],
                  },
                  {
                    type: 'change',
                    value: {
                      id: 3,
                      value: 'Collaborator',
                    },
                    Mapto: [
                      {
                        id: 'main.wrapper_171.wrapper_219',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_171.wrapper_857',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_171.wrapper_475',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_171.wrapper_215',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_171.wrapper_256',
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_171.wrapper_136',
                        type: 'showField',
                      },
                    ],
                  },
                ],
              },
            ],
            type: '',
            name: 'wrapper_273',
            classname: 'col-12 text-center',
            wrapperType: 'BOX',
            id: 'wrapper_677',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_922',
        classname: 'row ',
        wrapperType: 'BOX',
        id: 'wrapper_171',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'vendor',
                id: 'vendor',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['vendor'],
                      inv_type_perma: 'cell-lines',
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Vendor',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_750',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_857',
            fields: [],
            head: '',
            tabHeadClass: '',
            disableValue: {
              vendor: {
                id: 0,
                value: '',
              },
            },
          },
          {
            control: [
              {
                type: 'text',
                name: 'catalogue_no',
                id: 'catalogue_no',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                requiredErrorMessage: 'This field is required',
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Catalogue No',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_782',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_475',
            fields: [],
            head: '',
            tabHeadClass: '',
            disableValue: {
              catalogue_no: null,
            },
          },
          {
            control: [
              {
                type: 'text',
                name: 'product_url',
                id: 'product_url',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                requiredErrorMessage: 'This field is required',
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Product URL',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_867',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_215',
            fields: [],
            head: '',
            tabHeadClass: '',
            disableValue: {
              product_url: null,
            },
          },
          {
            control: [
              {
                type: 'text',
                name: 'in_house_id',
                id: 'in_house_id',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'In House  Source ID',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_771',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_219',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
            disableValue: {
              in_house_id: null,
            },
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'collaborator',
                id: 'collaborator',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['collab'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Collaborator Name',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_191',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_256',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
            disableValue: {
              collaborator: {
                id: 0,
                value: '',
              },
            },
          },
          {
            control: [
              {
                type: 'text',
                name: 'collaborator_source_id',
                id: 'collaborator_source_id',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Collaborator Source Id',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_811',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_136',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
            disableValue: {
              collaborator_source_id: null,
            },
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_923',
        classname: 'row ',
        wrapperType: 'BOX',
        id: 'wrapper_171',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'format',
                id: 'format',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['samp_form'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Format',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_738',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_404',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'no_of_vials',
                id: 'no_of_vials',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: {
                  noData: 'Please select total no: of vials',
                  regexFail: '',
                },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [
                  {
                    regularExpression: '/^[1-9][0-9]*$/',
                    errorMessage: 'Please type a valid non zero integer',
                  },
                ],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'Please select total no: of vials',
                showInputLabel: true,
                labelContent: 'Total No. of Vials',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_652',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_881',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'volume_per_vial',
                id: 'volume_per_vial',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [
                  {
                    regularExpression: '/^(0.[1-9]+|[1-9]d*(.d+)?)$/',
                    errorMessage: 'Please type a valid non zero number',
                  },
                ],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Volume per  Vial (µL)',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_364',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_955',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'tot_cell_count',
                id: 'tot_cell_count',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [
                  {
                    regularExpression: '/^(0.[1-9]+|[1-9]d*(.d+)?)$/',
                    errorMessage: 'Please type a valid non zero number',
                  },
                ],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Total Cell  Count(10^6)',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_233',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_329',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'viability',
                id: 'viability',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                requiredErrorMessage: 'This field is required',
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [
                  {
                    regularExpression: '/^(0.[1-9]+|[1-9]d*(.d+)?)$/',
                    errorMessage: 'Please type a valid non zero number',
                  },
                ],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Viability %',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_622',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_606',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'biosafety_level',
                id: 'biosafety_level',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['bio_level'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: {
                  id: 2,
                  value: 'BSL-2',
                },
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Biosafety Level',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_967',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_216',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'mammalian_resistance',
                id: 'mammalian_resistance',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['mam_rest'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Mammalian Resistance',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_487',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_5709',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'radio',
                name: 'is_expire',
                label: {
                  type: 'label',
                  name: '',
                  classname: '',
                  for: '',
                  required: false,
                  wrapperClassName: '',
                },
                id: 'is_expire',
                value: 1,
                required: false,
                classname: 'form-check-wrap',
                errorMessage: {
                  noData: '',
                },
                options: [
                  {
                    key: 1,
                    label: 'Yes',
                  },
                  {
                    key: 0,
                    label: 'No',
                  },
                ],
                functionName: '',
                readonly: false,
                validationButtonType: 'submit',
                disabled: false,
                wrapperClassName: 'form-check form-check-inline',
                showInputLabel: true,
                labelContent: 'Expiry Date',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
                controlPoints: [
                  {
                    type: 'change',
                    value: 1,
                    Mapto: [
                      {
                        id: 'main.wrapper_171.wrapper_57',
                        type: 'showField',
                      },
                    ],
                  },
                  {
                    type: 'change',
                    value: 0,
                    Mapto: [
                      {
                        id: 'main.wrapper_171.wrapper_57',
                        type: 'hideField',
                      },
                    ],
                  },
                ],
              },
            ],
            type: '',
            name: 'wrapper_334',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_585',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'expiry_date',
                id: 'expiry_date',
                validationButtonType: 'submit',
                range: false,
                value: '',
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: 'CURRENTDATE',
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                required: true,
                classname: 'date-wrap',
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: 'Date is not valid!!',
                },
                errorClass: 'errorMessage',
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-GB',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: '',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_487',
            classname: 'col-3 date-no-label mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_57',
            fields: [],
            head: '',
            tabHeadClass: '',
            disableValue: {
              expiry_date: '',
            },
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'storage_temp',
                id: 'storage_temp',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['st_temp'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Storage Temp (°C)',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_127',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_749',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'received_date',
                id: 'received_date',
                validationButtonType: 'submit',
                readonly: false,
                range: false,
                value: '',
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: '',
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                required: false,
                classname: '',
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: 'Date is not valid!!',
                },
                errorClass: 'errorMessage',
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-GB',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: 'Received Date',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_7635',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_9596',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
            disableValue: {
              expiry_date: '',
            },
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'date_of_storage',
                id: 'date_of_storage',
                validationButtonType: 'submit',
                readonly: false,
                range: false,
                value: 'CURRENTDATE',
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: 'CURRENTDATE',
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                required: true,
                classname: '',
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: 'Date is not valid!!',
                },
                errorClass: 'errorMessage',
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-GB',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: 'Date of Storage',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_7633',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_95989',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
            disableValue: {
              expiry_date: '',
            },
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  //Add new bacterial strain
  {
    jsonId: 6,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [
          {
            type: 'text',
            name: 'bacterialstrains_name',
            id: 'bacterialName',
            value: '',
            placeholder: '',
            multiline: false,
            classname: 'form-control ',
            regularExpression: '',
            disabled: false,
            required: true,
            errorMessage: { noData: 'This field is required', regexFail: '' },
            requiredErrorMessage: 'This field is required',
            validationButtonType: 'submit',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Bacterial Strain Name',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_584',
        classname: 'form-group',
        wrapperType: 'BOX',
        id: 'wrapper_299',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [
          {
            type: 'text',
            name: 'bacterialstrains_desc',
            id: 'bacterialDescription',
            value: '',
            placeholder: '',
            multiline: true,
            classname: 'form-control textarea',
            regularExpression: '',
            disabled: false,
            required: true,
            errorMessage: { noData: 'This field is required', regexFail: '' },
            requiredErrorMessage: 'This field is required',
            validationButtonType: 'submit',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Description',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_946',
        classname: 'form-group',
        wrapperType: 'BOX',
        id: 'wrapper_547',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },

  //Add new inventory bacterial strain
  {
    jsonId: 7,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [
          {
            type: 'radio',
            name: 'source',
            label: {
              type: 'label',
              name: '',
              classname: '',
              for: '',
              required: false,
              wrapperClassName: '',
            },
            id: 'source',
            value: {
              id: 1,
              value: 'In House',
            },
            required: true,
            classname: 'form-check-wrap form-source',
            errorMessage: {
              noData: '',
            },
            options: [
              {
                key: {
                  id: 1,
                  value: 'In House',
                },
                label: 'In House',
              },
              {
                key: {
                  id: 2,
                  value: 'Commercial',
                },
                label: 'Commercial',
              },
              {
                key: {
                  id: 3,
                  value: 'Collaborator',
                },
                label: 'Collaborator',
              },
            ],
            functionName: '',
            readonly: false,
            validationButtonType: 'submit',
            disabled: false,
            wrapperClassName: 'form-check form-check-inline',
            showInputLabel: true,
            labelContent: 'Source',
            labelClass: 'form-label mandatory ',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
            controlPoints: [
              {
                type: 'change',
                value: {
                  id: 1,
                  value: 'In House',
                },
                Mapto: [
                  {
                    id: 'main.wrapper_778.wrapper_5852',
                    type: 'showField',
                  },
                  {
                    id: 'main.wrapper_778.wrapper_606',
                    type: 'hideField',
                  },
                  {
                    id: 'main.wrapper_778.wrapper_577',
                    type: 'hideField',
                  },
                  {
                    id: 'main.wrapper_778.wrapper_74.wrapper_732',
                    type: 'hideField',
                  },
                  {
                    id: 'main.wrapper_778.wrapper_74.wrapper_532',
                    type: 'hideField',
                  },
                  {
                    id: 'main.wrapper_778.wrapper_74.wrapper_661',
                    type: 'hideField',
                  },
                ],
              },
              {
                type: 'change',
                value: {
                  id: 2,
                  value: 'Commercial',
                },
                Mapto: [
                  {
                    id: 'main.wrapper_778.wrapper_74.wrapper_732',
                    type: 'showField',
                  },
                  {
                    id: 'main.wrapper_778.wrapper_5852',
                    type: 'hideField',
                  },
                  {
                    id: 'main.wrapper_778.wrapper_74.wrapper_532',
                    type: 'showField',
                  },
                  {
                    id: 'main.wrapper_778.wrapper_74.wrapper_661',
                    type: 'showField',
                  },
                  {
                    id: 'main.wrapper_778.wrapper_606',
                    type: 'hideField',
                  },
                  {
                    id: 'main.wrapper_778.wrapper_577',
                    type: 'hideField',
                  },
                ],
              },
              {
                type: 'change',
                value: {
                  id: 3,
                  value: 'Collaborator',
                },
                Mapto: [
                  {
                    id: 'main.wrapper_778.wrapper_74.wrapper_732',
                    type: 'hideField',
                  },
                  {
                    id: 'main.wrapper_778.wrapper_5852',
                    type: 'hideField',
                  },
                  {
                    id: 'main.wrapper_778.wrapper_74.wrapper_532',
                    type: 'hideField',
                  },
                  {
                    id: 'main.wrapper_778.wrapper_577',
                    type: 'showField',
                  },
                  {
                    id: 'main.wrapper_778.wrapper_606',
                    type: 'showField',
                  },
                  {
                    id: 'main.wrapper_778.wrapper_74.wrapper_661',
                    type: 'hideField',
                  },
                ],
              },
            ],
          },
        ],
        type: '',
        name: 'wrapper_529',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_677',
        fields: [],
        head: '',
        tabHeadClass: '',
      },
      {
        control: [],
        type: '',
        name: 'wrapper_208',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_778',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'in_house_id',
                id: 'in_house_id',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'In House Source ID',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_5425',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_5852',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
            disableValue: {
              in_house_id: null,
            },
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'collaborator',
                id: 'collaboratorName',
                value: '',
                placeholder: '',
                multiline: false,
                classname: '',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Collaborator Name ',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                showLabel: 'value',
                draggable: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['collab'],
                    },
                  },
                ],
              },
            ],
            type: '',
            name: 'wrapper_622',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_606',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
            disableValue: {
              collaborator: {
                id: 0,
                value: '',
              },
            },
          },
          {
            control: [
              {
                type: 'text',
                name: 'collaborator_source_id',
                id: 'collaborator_source_id',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Collaborator Source Id',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_914',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_577',
            fields: [],
            head: '',
            tabHeadClass: '',
            disableValue: {
              collaborator_source_id: null,
            },
            draggable: false,
          },
          {
            control: [],
            type: '',
            name: 'wrapper_19',
            classname: 'row',
            wrapperType: 'BOX',
            id: 'wrapper_74',
            fields: [
              {
                control: [
                  {
                    type: 'dropdown',
                    showBlankInPlaceholder: true,
                    blankDataList: ['""', 'undefined', 'null'],
                    name: 'vendor',
                    id: 'vendor',
                    validationButtonType: 'submit',
                    classname: '',
                    required: true,
                    apiEndPoints: [
                      {
                        type: 'init',
                        method: 'post',
                        url: 'masterdata',
                        payload: {
                          data_types: ['vendor'],
                          inv_type_perma: 'bacterial-strains',
                        },
                      },
                    ],
                    options: [],
                    multiple: false,
                    isClearable: false,
                    isStatus: false,
                    search: false,
                    singleSelect: true,
                    showLabel: 'value',
                    errorMessage: {
                      noData: 'This field is required',
                    },
                    value: '',
                    imageUrl: '',
                    disabled: false,
                    wrapperClassName: '',
                    showInputLabel: true,
                    labelContent: 'Vendor',
                    labelClass: 'form-label mandatory',
                    isLabelAfter: false,
                    showAdditionalInputContent: false,
                    additionalInputContent: '',
                    isAdditonalInputAfter: true,
                    draggable: false,
                  },
                ],
                type: '',
                name: 'wrapper_173',
                classname: 'col-3 mb-3',
                wrapperType: 'BOX',
                id: 'wrapper_732',
                fields: [],
                head: '',
                tabHeadClass: '',
                disableValue: {
                  vendor: {
                    id: 0,
                    value: '',
                  },
                },
              },
              {
                control: [
                  {
                    type: 'text',
                    name: 'catalogue_no',
                    id: 'catalogueNo',
                    value: '',
                    placeholder: '',
                    multiline: false,
                    classname: 'form-control ',
                    regularExpression: '',
                    disabled: false,
                    required: true,
                    errorMessage: {
                      noData: 'This field is required',
                      regexFail: '',
                    },
                    validationButtonType: 'submit',
                    rows: 0,
                    columns: 0,
                    onDemandError: false,
                    customValidation: [],
                    multiError: false,
                    applicationError: '',
                    wrapperClassName: '',
                    requiredErrorMessage: 'This field is required',
                    showInputLabel: true,
                    labelContent: 'Catalogue No',
                    labelClass: 'form-label mandatory',
                    isLabelAfter: false,
                    showAdditionalInputContent: false,
                    additionalInputContent: '',
                    isAdditonalInputAfter: true,
                    draggable: false,
                  },
                ],
                type: '',
                name: 'wrapper_969',
                classname: 'col-3 mb-3',
                wrapperType: 'BOX',
                id: 'wrapper_532',
                fields: [],
                head: '',
                tabHeadClass: '',
                disableValue: {
                  catalogue_no: null,
                },
              },
              {
                control: [
                  {
                    type: 'text',
                    name: 'product_url',
                    id: 'productUrl',
                    value: '',
                    placeholder: '',
                    multiline: false,
                    classname: 'form-control ',
                    regularExpression: '',
                    disabled: false,
                    required: true,
                    errorMessage: {
                      noData: 'This field is required',
                      regexFail: '',
                    },
                    validationButtonType: 'submit',
                    rows: 0,
                    columns: 0,
                    onDemandError: false,
                    customValidation: [],
                    multiError: false,
                    applicationError: '',
                    wrapperClassName: '',
                    requiredErrorMessage: 'This field is required',
                    showInputLabel: true,
                    labelContent: 'Product URL',
                    labelClass: 'form-label mandatory',
                    isLabelAfter: false,
                    showAdditionalInputContent: false,
                    additionalInputContent: '',
                    isAdditonalInputAfter: true,
                    draggable: false,
                  },
                ],
                type: '',
                name: 'wrapper_708',
                classname: 'col-6 mb-3',
                wrapperType: 'BOX',
                id: 'wrapper_661',
                fields: [],
                head: '',
                tabHeadClass: '',
                disableValue: {
                  product_url: null,
                },
              },
            ],
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'format',
                id: 'Format',
                value: '',
                placeholder: '',
                multiline: false,
                classname: '',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Format',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
                showLabel: 'value',
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['samp_form'],
                    },
                  },
                ],
              },
            ],
            type: '',
            name: 'wrapper_308',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_994',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'total_no_of_tubes',
                id: 'total_no_of_tubes',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [
                  {
                    regularExpression: '/^[1-9][0-9]*$/',
                    errorMessage: 'Please type a valid non zero integer',
                  },
                ],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'Please select total no: of tubes',
                showInputLabel: true,
                labelContent: 'Total No. of Tubes',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_811',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_136',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'quantity_per_tube',
                id: 'Volume',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [
                  {
                    regularExpression: '/^(0\\.[1-9]+|[1-9]\\d*(\\.\\d+)?)$/',
                    errorMessage: 'Please type a valid non zero number',
                  },
                ],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Volume per Tube (µL)',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_8411',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_1367',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'concentration',
                id: 'Conc',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [
                  {
                    regularExpression: '/^(0\\.[1-9]+|[1-9]\\d*(\\.\\d+)?)$/',
                    errorMessage: 'Please type a valid non zero number',
                  },
                ],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Conc (µg/µL)',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_84211',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_13167',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_303',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_598',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'biosafety_level',
                id: 'dropDown_206',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['bio_level'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: {
                  id: 2,
                  value: 'BSL-2',
                },
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Biosafety Level',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_8412',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_1368',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'radio',
                name: 'is_expire',
                label: {
                  type: 'label',
                  name: '',
                  classname: '',
                  for: '',
                  required: false,
                  wrapperClassName: '',
                },
                id: 'radioButton_79',
                value: 1,
                required: false,
                classname: 'form-check-wrap',
                errorMessage: {
                  noData: '',
                },
                options: [
                  {
                    key: 1,
                    label: 'Yes',
                  },
                  {
                    key: 0,
                    label: 'No',
                  },
                ],
                functionName: '',
                readonly: false,
                validationButtonType: 'submit',
                disabled: false,
                wrapperClassName: 'form-check form-check-inline',
                showInputLabel: true,
                labelContent: 'Expiry Date',
                labelClass: 'form-label mandatory ',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
                controlPoints: [
                  {
                    type: 'change',
                    value: 1,
                    Mapto: [
                      {
                        id: 'main.wrapper_598.wrapper_1370',
                        type: 'showField',
                      },
                    ],
                  },
                  {
                    type: 'change',
                    value: 0,
                    Mapto: [
                      {
                        id: 'main.wrapper_598.wrapper_1370',
                        type: 'hideField',
                      },
                    ],
                  },
                ],
              },
            ],
            type: '',
            name: 'wrapper_8413',
            classname: 'col-3 mb-3 ',
            wrapperType: 'BOX',
            id: 'wrapper_1369',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'expiry_date',
                id: 'datePicker',
                validationButtonType: 'submit',
                range: false,
                value: '',
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: 'CURRENTDATE',
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                required: true,
                classname: '',
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: 'Date is not valid!!',
                },
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-GB',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: '',
                labelClass: 'form-label ',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
                errorClass: 'errorMessage',
              },
            ],
            type: '',
            name: 'wrapper_8414',
            classname: 'col-3 mb-3 date-no-label',
            wrapperType: 'BOX',
            id: 'wrapper_1370',
            fields: [],
            head: '',
            tabHeadClass: '',
            disableValue: {
              expiry_date: '',
            },
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'storage_temp',
                id: 'storage_temp',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['st_temp'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Storage Temperature (°C)',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_8415',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_1371',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'received_date',
                id: 'received_date',
                validationButtonType: 'submit',
                readonly: false,
                range: false,
                value: '',
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: '',
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                required: false,
                classname: '',
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: 'Date is not valid!!',
                },
                errorClass: 'errorMessage',
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-GB',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: 'Received Date',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_7635',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_9596',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
            disableValue: {
              expiry_date: '',
            },
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'date_of_storage',
                id: 'date_of_storage',
                validationButtonType: 'submit',
                readonly: false,
                range: false,
                value: 'CURRENTDATE',
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: 'CURRENTDATE',
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                required: true,
                classname: '',
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: 'Date is not valid!!',
                },
                errorClass: 'errorMessage',
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-GB',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: 'Date of Storage',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_7633',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_95989',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
            disableValue: {
              expiry_date: '',
            },
          },
        ],
      },
    ],
    type: '',
    classname: '',
  },

  //add new Refrigerator
  {
    jsonId: 8,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [
          {
            type: 'text',
            name: 'refrigerator_name',
            id: 'refrigerator_name',
            value: '',
            placeholder: '',
            multiline: false,
            classname: 'form-control ',
            regularExpression: '',
            disabled: false,
            required: true,
            errorMessage: { noData: 'This field is required', regexFail: '' },
            validationButtonType: 'submit',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: '',
            requiredErrorMessage: 'This field is required',
            showInputLabel: true,
            labelContent: 'Refrigerator Type',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_457',
        classname: 'col-12 mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_308',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [
          {
            type: 'dropdown',
            showBlankInPlaceholder: true,
            blankDataList: ['""', 'undefined', 'null'],
            name: 'refrigerator_temp',
            id: 'refrigerator_temp',
            validationButtonType: 'submit',
            classname: '',
            required: true,
            apiEndPoints: [
              {
                type: 'init',
                method: 'post',
                url: 'masterdata',
                payload: {
                  data_types: ['st_temp'],
                },
              },
            ],
            options: [],
            multiple: false,
            isClearable: false,
            isStatus: false,
            search: false,
            singleSelect: true,
            showLabel: 'value',
            errorMessage: { noData: 'This field is required' },
            requiredErrorMessage: 'This field is required',
            value: '',
            imageUrl: '',
            disabled: false,
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Storage Temperature(°C)',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_120',
        classname: 'col-12 mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_994',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [
          {
            type: 'text',
            name: 'refrigerator_desc',
            id: 'refrigerator_desc',
            value: '',
            placeholder: '',
            multiline: true,
            classname: 'form-control  textarea',
            regularExpression: '',
            disabled: false,
            required: true,
            errorMessage: { noData: 'This field is required', regexFail: '' },
            validationButtonType: 'submit',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: '',
            requiredErrorMessage: 'This field is required',
            showInputLabel: true,
            labelContent: 'Description',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_775',
        classname: 'col-12 mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_925',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },

  //add inventory Refrigerator
  {
    jsonId: 9,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_753',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_260',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'lab_equip_inv_master_name',
                id: 'lab_equip_inv_master_name',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata/master-type-list',
                    payload: ['refrigerator'],
                  },
                  {
                    type: 'change',
                    method: '',
                    Mapto: [
                      {
                        id: 'main.wrapper_260.wrapper_779.lab_equip_inv_temp',
                        type: 'text',
                        urlParameterValues: { storage_temp: null },
                      },
                    ],
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Select Refrigerator Type',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_228',
            classname: 'col-3 ',
            wrapperType: 'BOX',
            id: 'wrapper_821',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'lab_equip_inv_temp',
                id: 'lab_equip_inv_temp',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: true,
                required: true,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Storage Temperature (°C)',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_119',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_779',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'lab_equip_inv_name',
                id: 'lab_equip_inv_name',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Refrigerator Name',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_463',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_188',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_208',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_778',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'lab_equip_inv_vendor',
                id: 'lab_equip_inv_vendor',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['vendor'],
                      inv_type_perma: 'refrigerator',
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Vendor',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_750',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_857',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'lab_equip_inv_cat_no',
                id: 'lab_equip_inv_cat_no',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Catalogue No',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_811',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_136',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'lab_equip_inv_products_url',
                id: 'lab_equip_inv_products_url',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Product Url',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_632',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_157',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_303',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_598',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'lab_equip_inv_no_of_boxes',
                id: 'lab_equip_inv_no_of_boxes',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: true,
                customValidation: [{ regularExpression: /^\d+(\.\d+)?$/, errorMessage: 'Please Type a Number' }],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'No of Boxes',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_967',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_216',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'lab_equip_inv_storage',
                id: 'lab_equip_inv_storage',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: true,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Storage Room',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_334',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_585',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_902',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_539',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'lab_equip_inv_desc',
                id: 'lab_equip_inv_desc',
                value: '',
                placeholder: '',
                multiline: true,
                classname: 'form-control  textarea',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Description/Comments',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_41',
            classname: 'col-12',
            wrapperType: 'BOX',
            id: 'wrapper_45',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },

  //add new inventory freezer
  {
    jsonId: 10,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_753',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_260',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'lab_equip_inv_master_name',
                id: 'lab_equip_inv_master_name',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata/master-type-list',
                    payload: ['freezer'],
                  },
                  {
                    type: 'change',
                    method: '',
                    Mapto: [
                      {
                        id: 'main.wrapper_260.wrapper_779.lab_equip_inv_temp',
                        type: 'text',
                        urlParameterValues: { storage_temp: null },
                      },
                    ],
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Select Freezer Type',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_228',
            classname: 'col-3 ',
            wrapperType: 'BOX',
            id: 'wrapper_821',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'lab_equip_inv_temp',
                id: 'lab_equip_inv_temp',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: true,
                required: true,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Storage Temperature (°C)',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_119',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_779',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'lab_equip_inv_name',
                id: 'lab_equip_inv_name',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Freezer Name',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_463',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_188',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_208',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_778',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'lab_equip_inv_vendor',
                id: 'lab_equip_inv_vendor',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['vendor'],
                      inv_type_perma: 'freezer',
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Vendor',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_750',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_857',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'lab_equip_inv_cat_no',
                id: 'lab_equip_inv_cat_no',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Catalogue No',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_811',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_136',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'lab_equip_inv_products_url',
                id: 'lab_equip_inv_products_url',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Product Url',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_632',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_157',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_303',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_598',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'lab_equip_inv_no_of_boxes',
                id: 'lab_equip_inv_no_of_boxes',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: true,
                customValidation: [{ regularExpression: '/^d+(.d+)?$/', errorMessage: 'Please Type a Number' }],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'No of Boxes',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_967',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_216',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'lab_equip_inv_storage',
                id: 'lab_equip_inv_storage',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: true,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Storage Room',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_334',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_585',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_902',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_539',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'lab_equip_inv_desc',
                id: 'lab_equip_inv_desc',
                value: '',
                placeholder: '',
                multiline: true,
                classname: 'form-control  textarea',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Description/Comments',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_41',
            classname: 'col-12',
            wrapperType: 'BOX',
            id: 'wrapper_45',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },

  //add new Freezer
  {
    jsonId: 11,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [
          {
            type: 'text',
            name: 'freezer_name',
            id: 'freezer_name',
            value: '',
            placeholder: '',
            multiline: false,
            classname: 'form-control ',
            regularExpression: '',
            disabled: false,
            required: true,
            errorMessage: { noData: 'This field is required', regexFail: '' },
            validationButtonType: 'submit',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: '',
            requiredErrorMessage: 'This field is required',
            showInputLabel: true,
            labelContent: 'Freezer Type',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_457',
        classname: 'col-12 mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_308',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [
          {
            type: 'dropdown',
            showBlankInPlaceholder: true,
            blankDataList: ['""', 'undefined', 'null'],
            name: 'freezer_temp',
            id: 'freezer_temp',
            validationButtonType: 'submit',
            classname: '',
            required: true,
            apiEndPoints: [
              {
                type: 'init',
                method: 'post',
                url: 'masterdata',
                payload: {
                  data_types: ['st_temp'],
                },
              },
            ],
            options: [],
            multiple: false,
            isClearable: false,
            isStatus: false,
            search: false,
            singleSelect: true,
            showLabel: 'value',
            errorMessage: { noData: 'This field is required' },
            requiredErrorMessage: 'This field is required',
            value: '',
            imageUrl: '',
            disabled: false,
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Storage Temperature(°C)',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_120',
        classname: 'col-12 mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_994',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [
          {
            type: 'text',
            name: 'freezer_desc',
            id: 'freezer_desc',
            value: '',
            placeholder: '',
            multiline: true,
            classname: 'form-control  textarea',
            regularExpression: '',
            disabled: false,
            required: true,
            errorMessage: { noData: 'This field is required', regexFail: '' },
            validationButtonType: 'submit',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: '',
            requiredErrorMessage: 'This field is required',
            showInputLabel: true,
            labelContent: 'Description',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_775',
        classname: 'col-12 mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_925',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },

  //plasmid inventory filter
  {
    jsonId: 12,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_817',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_409',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'plasmid_type',
                id: 'plasmid_type',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'inventory-plasmids/inventory-sub-types',
                    payload: {
                      perma: ['plasmids'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Plasmid Type',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_128',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_929',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'plasmid_name',
                id: 'plasmid_name',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata/master-type-list',
                    payload: ['plasmids'],
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Plasmid Name (Master)',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_312',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_481',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_725',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_998',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'plasmid_id',
                id: 'plasmid_id',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Plasmid ID',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_570',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_467',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'quantity_on_hand',
                id: 'quantity_on_hand',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [{ regularExpression: '/^d+(.d+)?$/', errorMessage: 'Please Type a Number' }],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Quantity on Hand',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_119',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_282',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_666',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_367',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'source',
                id: 'source',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [],
                options: [
                  {
                    id: 0,
                    value: 'All',
                  },
                  {
                    id: 1,
                    value: 'In House',
                  },
                  {
                    id: 2,
                    value: 'Commercial',
                  },
                  {
                    id: 3,
                    value: 'Collaborator',
                  },
                ],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: {
                  id: 0,
                  value: 'All',
                },
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Source',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
                controlPoints: [
                  {
                    type: 'change',
                    value: null,
                    Mapto: [
                      {
                        id: 'main.wrapper_367.wrapper_282',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_926',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_026',
                        type: 'hideField',
                      },
                    ],
                  },
                  {
                    type: 'change',
                    value: {
                      id: 4,
                      value: 'All',
                    },
                    Mapto: [
                      {
                        id: 'main.wrapper_367.wrapper_282',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_926',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_026',
                        type: 'hideField',
                      },
                    ],
                  },
                  {
                    type: 'change',
                    value: {
                      id: 1,
                      value: 'In House',
                    },
                    Mapto: [
                      {
                        id: 'main.wrapper_367.wrapper_282',
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_926',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_026',
                        type: 'hideField',
                      },
                    ],
                  },
                  {
                    type: 'change',
                    value: {
                      id: 2,
                      value: 'Commercial',
                    },
                    Mapto: [
                      {
                        id: 'main.wrapper_367.wrapper_282',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_926',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_026',
                        type: 'showField',
                      },
                    ],
                  },
                  {
                    type: 'change',
                    value: {
                      id: 3,
                      value: 'Collaborator',
                    },
                    Mapto: [
                      {
                        id: 'main.wrapper_367.wrapper_282',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_926',
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_026',
                        type: 'hideField',
                      },
                    ],
                  },
                ],
              },
            ],
            type: '',
            name: 'wrapper_201',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_826',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'in_house_id',
                id: 'in_house_id',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'In House Id',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_119',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_282',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'collaborator',
                id: 'collaborator',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['collab'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Collaborator',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_201',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_926',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'vendor',
                id: 'vendor',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['vendor'],
                      inv_type_perma: 'plasmids',
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Vendor',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_201',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_026',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'format',
                id: 'format',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['samp_form'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Format',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_645',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_997',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'quantity_per_tube',
                id: 'quantity_per_tube',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [{ regularExpression: '/^d+(.d+)?$/', errorMessage: 'Please Type a Number' }],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Quantity Per Tube (µg)',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_720',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_555',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'bacterial_resistance',
                id: 'bacterial_resistance',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['bact_rest'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Bacterial Resistance',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_234',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_244',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'mammalian_resistance',
                id: 'mammalian_resistance',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['mam_rest'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Mammalian Resistance',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_891',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_42',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'expiry_date',
                id: 'expiry_date',
                validationButtonType: '',
                range: true,
                value: '',
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: '',
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                regularExpression:
                  '^(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/\\d{4}\\s+to\\s+(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/\\d{4}$',
                required: false,
                classname: '',
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: 'Date is not valid!!',
                },
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-GB',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: 'Expiry Date',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_749',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_285',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'days_till_expiration',
                id: 'days_till_expiration',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [{ regularExpression: '/^d+(.d+)?$/', errorMessage: 'Please Type a Number' }],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Days till Expiration',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_284',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_488',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },

  // plasmid master search
  {
    jsonId: 13,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_661',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_986',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'plasmid_type',
                id: 'plasmid_type',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'inventory-plasmids/inventory-sub-types',
                    payload: {
                      perma: ['plasmids'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Plasmid Type',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_656',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_306',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'plasmid_name',
                id: 'plasmid_name',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Plasmid Name',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_519',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_252',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },

  // refrigerator master search
  {
    jsonId: 14,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_661',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_986',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'refrigerator_name',
                id: 'refrigerator_name',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Refrigerator Type',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_519',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_252',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'refrigerator_temp',
                id: 'refrigerator_temp',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['st_temp'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Storage Temperature(°C)',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_656',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_306',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },

  // freezer master search
  {
    jsonId: 15,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_661',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_986',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'freezer_name',
                id: 'freezer_name',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Freezer Type',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_519',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_252',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'freezer_temp',
                id: 'freezer_temp',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['st_temp'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Storage Temperature(°C)',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_656',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_306',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },

  //refrigerator inventory filter
  {
    jsonId: 16,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_817',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_409',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'inv_name',
                id: 'inv_name',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Refrigerator Name',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_519',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_252',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'inv_temp',
                id: 'inv_temp',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['st_temp'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Storage Temperature',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_128',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_929',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'inv_vendor',
                id: 'inv_vendor',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['vendor'],
                      inv_type_perma: 'refrigerator',
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Vendor',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_312',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_481',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  // freezer inventory filter
  {
    jsonId: 17,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_817',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_409',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'inv_name',
                id: 'inv_name',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Freezer Name',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_519',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_252',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'inv_temp',
                id: 'inv_temp',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['st_temp'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Storage Temperature',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_128',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_929',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'inv_vendor',
                id: 'inv_vendor',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['vendor'],
                      inv_type_perma: 'freezer',
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Vendor',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_312',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_481',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },

  //cell line inventory filter
  {
    jsonId: 18,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_666',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_367',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'source',
                id: 'source',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [],
                options: [
                  {
                    id: 0,
                    value: 'All',
                  },
                  {
                    id: 2,
                    value: 'Commercial',
                  },
                  {
                    id: 3,
                    value: 'Collaborator',
                  },
                  {
                    id: 4,
                    value: 'In House Expanded',
                  },
                  {
                    id: 5,
                    value: 'In House Created',
                  },
                ],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: {
                  id: 0,
                  value: 'All',
                },
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Source',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
                controlPoints: [
                  {
                    type: 'change',
                    value: null,
                    Mapto: [
                      {
                        id: 'main.wrapper_367.wrapper_282',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_926',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_31',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_026',
                        type: 'hideField',
                      },
                    ],
                  },
                  {
                    type: 'change',
                    value: {
                      id: 0,
                      value: 'All',
                    },
                    Mapto: [
                      {
                        id: 'main.wrapper_367.wrapper_282',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_926',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_31',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_026',
                        type: 'hideField',
                      },
                    ],
                  },
                  {
                    type: 'change',
                    value: {
                      id: 2,
                      value: 'Commercial',
                    },
                    Mapto: [
                      {
                        id: 'main.wrapper_367.wrapper_282',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_926',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_31',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_026',
                        type: 'showField',
                      },
                    ],
                  },
                  {
                    type: 'change',
                    value: {
                      id: 3,
                      value: 'Collaborator',
                    },
                    Mapto: [
                      {
                        id: 'main.wrapper_367.wrapper_282',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_926',
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_31',
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_026',
                        type: 'hideField',
                      },
                    ],
                  },
                  {
                    type: 'change',
                    value: {
                      id: 4,
                      value: 'In House Expanded',
                    },
                    Mapto: [
                      {
                        id: 'main.wrapper_367.wrapper_282',
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_926',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_026',
                        type: 'hideField',
                      },
                    ],
                  },
                  {
                    type: 'change',
                    value: {
                      id: 5,
                      value: 'In House Created',
                    },
                    Mapto: [
                      {
                        id: 'main.wrapper_367.wrapper_282',
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_926',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_026',
                        type: 'hideField',
                      },
                    ],
                  },
                ],
              },
            ],
            type: '',
            name: 'wrapper_201',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_826',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'in_house_id',
                id: 'in_house_id',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'In House Id',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_119',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_282',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'collaborator',
                id: 'collaborator',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['collab'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Collaborator',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_201',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_926',
            fields: [],
            head: '',
            tabHeadClass: '',
            disableValue: {
              collaborator: { id: 0, value: '' },
            },
          },
          {
            control: [
              {
                type: 'text',
                name: 'collaborator_source_id',
                id: 'collaborator_source_id',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Collaborator Source Id',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_707',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_31',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
            disableValue: {
              collaborator_source_id: '',
            },
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'vendor',
                id: 'vendor',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['vendor'],
                      inv_type_perma: 'cell-lines',
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Vendor',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_201',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_026',
            fields: [],
            head: '',
            tabHeadClass: '',
            disableValue: {
              vendor: { id: 0, value: '' },
            },
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'format',
                id: 'format',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['samp_form'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Format',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_645',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_997',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },

      {
        control: [],
        type: '',
        name: 'wrapper_720',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_228',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'no_of_vials',
                id: 'no_of_vials',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [{ regularExpression: '/^d+(.d+)?$/', errorMessage: 'Please type a number' }],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Quantity on Hand',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_208',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_304',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'volume_per_vial',
                id: 'volume_per_vial',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [{ regularExpression: '/^d+(.d+)?$/', errorMessage: 'Please type a number' }],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Volume per Vial (µL)',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_720',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_555',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'tot_cell_count',
                id: 'tot_cell_count',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [{ regularExpression: '/^d+(.d+)?$/', errorMessage: 'Please type a number' }],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Total Cell Count(10^6)',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_720',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_556',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'viability',
                id: 'viability',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [{ regularExpression: '/^d+(.d+)?$/', errorMessage: 'Please type a number' }],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Viability %',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_720',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_5565',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },

      {
        control: [],
        type: '',
        name: 'wrapper_378',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_763',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'mammalian_resistance',
                id: 'mammalian_resistance',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['mam_rest'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Mammalian Resistance',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_645',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_997',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'expiry_date',
                id: 'expiry_date',
                validationButtonType: '',
                range: true,
                value: '',
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: '',
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                regularExpression:
                  '^(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/\\d{4}\\s+to\\s+(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/\\d{4}$',
                required: false,
                classname: 'mt-0',
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: 'Date is not valid!!',
                },
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-GB',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: 'Expiry Date',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_749',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_285',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  // cell line master search
  {
    jsonId: 19,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_661',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_986',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'inv_name',
                id: 'inv_name',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Cell Line Name',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_519',
            classname: 'col-12',
            wrapperType: 'BOX',
            id: 'wrapper_252',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  // bacterial strain master search
  {
    jsonId: 20,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_661',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_986',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'inv_name',
                id: 'bacterialstrains_name',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Bacterial Strain Name',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_519',
            classname: 'col-12',
            wrapperType: 'BOX',
            id: 'wrapper_252',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  //bacterial-strain inventory filter
  {
    jsonId: 21,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_666',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_367',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'source',
                id: 'source',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [],
                options: [
                  {
                    id: 0,
                    value: 'All',
                  },
                  {
                    id: 1,
                    value: 'In House',
                  },
                  {
                    id: 2,
                    value: 'Commercial',
                  },
                  {
                    id: 3,
                    value: 'Collaborator',
                  },
                ],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: {
                  id: 0,
                  value: 'All',
                },
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Source',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
                controlPoints: [
                  {
                    type: 'change',
                    value: null,
                    Mapto: [
                      {
                        id: 'main.wrapper_367.wrapper_282',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_926',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_026',
                        type: 'hideField',
                      },
                    ],
                  },
                  {
                    type: 'change',
                    value: {
                      id: 0,
                      value: 'All',
                    },
                    Mapto: [
                      {
                        id: 'main.wrapper_367.wrapper_282',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_926',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_026',
                        type: 'hideField',
                      },
                    ],
                  },
                  {
                    type: 'change',
                    value: {
                      id: 1,
                      value: 'In House',
                    },
                    Mapto: [
                      {
                        id: 'main.wrapper_367.wrapper_282',
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_926',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_026',
                        type: 'hideField',
                      },
                    ],
                  },
                  {
                    type: 'change',
                    value: {
                      id: 2,
                      value: 'Commercial',
                    },
                    Mapto: [
                      {
                        id: 'main.wrapper_367.wrapper_282',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_926',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_026',
                        type: 'showField',
                      },
                    ],
                  },
                  {
                    type: 'change',
                    value: {
                      id: 3,
                      value: 'Collaborator',
                    },
                    Mapto: [
                      {
                        id: 'main.wrapper_367.wrapper_282',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_926',
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_367.wrapper_026',
                        type: 'hideField',
                      },
                    ],
                  },
                ],
              },
            ],
            type: '',
            name: 'wrapper_201',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_826',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'in_house_id',
                id: 'in_house_id',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'In House Id',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_119',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_282',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'collaborator',
                id: 'collaborator',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['collab'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Collaborator',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_201',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_926',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'vendor',
                id: 'vendor',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['vendor'],
                      inv_type_perma: 'bacterial-strains',
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Vendor',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_201',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_026',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'format',
                id: 'format',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['samp_form'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Format',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_645',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_997',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'total_no_of_tubes',
                id: 'total_no_of_tubes',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [{ regularExpression: '/^d+(.d+)?$/', errorMessage: 'Please Type a Number' }],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Quantity on Hand',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_208',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_304',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'quantity_per_tube',
                id: 'quantity_per_tube',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [{ regularExpression: '/^d+(.d+)?$/', errorMessage: 'Please Type a Number' }],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Volume Per Tube (µL)',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_720',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_555',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'expiry_date',
                id: 'expiry_date',
                validationButtonType: '',
                range: true,
                value: '',
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: '',
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                regularExpression:
                  '^(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/\\d{4}\\s+to\\s+(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/\\d{4}$',
                required: false,
                classname: '',
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: 'Date is not valid!!',
                },
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-GB',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: 'Expiry Date',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_749',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_285',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  //file upload
  {
    jsonId: 22,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [
          {
            type: 'dropdown',
            showBlankInPlaceholder: true,
            blankDataList: ['""', 'undefined', 'null'],
            name: 'file_type',
            id: 'file_type',
            validationButtonType: 'upload',
            classname: '',
            required: true,
            apiEndPoints: [],
            options: [
              {
                key: 1,
                value: 'Plasmid Map',
              },
              {
                key: 2,
                value: 'Plasmid Sequence',
              },
            ],
            multiple: false,
            isClearable: false,
            isStatus: false,
            search: false,
            singleSelect: true,
            showLabel: 'value',
            errorMessage: { noData: 'This field is required' },
            value: '',
            imageUrl: '',
            disabled: false,
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Select File Type',
            labelClass: 'form-label',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_801',
        classname: 'col-12 mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_766',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [
          {
            type: 'file',
            name: 'file',
            id: 'file',
            value: 'upload',
            classname: 'btn btn-file',
            fileNameShow: true,
            size: 10,
            url: '',
            functionName: '',
            wrapperClassName: '',
            fileCount: 1,
            sizeKB: '50000',
            allowMultiple: false,
            required: true,
            validationButtonType: 'upload',
            formDataType: 'blob',
            errorMsg: { invalidFormat: 'Invalid File Format', invalidSize: 'File Size exceeded' },
            fileTypes: ['pdf', 'jpeg', 'png', 'jpg', 'xlsx', 'txt'],
            listClass: '',
            mainClass: '',
            btnDivClass: '',
            showInputLabel: true,
            labelContent: 'Drag and drop files here or browse files  ',
            labelClass: 'form-label',
            requiredErrorMessage: 'This field is required',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
            showViewIcon: false,
            showDownloadIcon: false,
          },
        ],
        type: '',
        name: 'wrapper_379',
        classname: 'col-12 mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_461',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  //add new Storage Cabinet
  {
    jsonId: 23,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [
          {
            type: 'text',
            name: 'lab_equipments_name',
            id: 'labEquipmentsName',
            value: '',
            placeholder: '',
            multiline: false,
            classname: 'form-control ',
            regularExpression: '',
            disabled: false,
            required: true,
            errorMessage: { noData: 'This field is required', regexFail: '' },
            validationButtonType: 'submit',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: '',
            requiredErrorMessage: 'This field is required',
            showInputLabel: true,
            labelContent: 'Storage Cabinet Type',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_457',
        classname: 'col-12 mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_308',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [
          {
            type: 'dropdown',
            showBlankInPlaceholder: true,
            blankDataList: ['""', 'undefined', 'null'],
            name: 'lab_equipments_temp',
            id: 'labEquipmentsTemp',
            validationButtonType: 'submit',
            classname: '',
            required: true,
            apiEndPoints: [
              {
                type: 'init',
                method: 'post',
                url: 'masterdata',
                payload: {
                  data_types: ['st_temp'],
                },
              },
            ],
            options: [],
            multiple: false,
            isClearable: false,
            isStatus: false,
            search: false,
            singleSelect: true,
            showLabel: 'value',
            errorMessage: { noData: 'This field is required' },
            requiredErrorMessage: 'This field is required',
            value: '',
            imageUrl: '',
            disabled: false,
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Storage Temperature(°C)',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_120',
        classname: 'col-12 mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_994',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [
          {
            type: 'text',
            name: 'lab_equipments_desc',
            id: 'labEquipmentsDesc',
            value: '',
            placeholder: '',
            multiline: true,
            classname: 'form-control  textarea',
            regularExpression: '',
            disabled: false,
            required: true,
            errorMessage: { noData: 'This field is required', regexFail: '' },
            validationButtonType: 'submit',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: '',
            requiredErrorMessage: 'This field is required',
            showInputLabel: true,
            labelContent: 'Description',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_775',
        classname: 'col-12 mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_925',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  //add new inventory Storage cabinet
  {
    jsonId: 25,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_753',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_260',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'lab_equip_inv_master_name',
                id: 'lab_equip_inv_master_name',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata/master-type-list',
                    payload: ['storage-cabinet'],
                  },
                  {
                    type: 'change',
                    method: '',
                    Mapto: [
                      {
                        id: 'main.wrapper_260.wrapper_779.lab_equip_inv_temp',
                        type: 'text',
                        urlParameterValues: { storage_temp: null },
                      },
                    ],
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Select Cabinet Type',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_228',
            classname: 'col-3 ',
            wrapperType: 'BOX',
            id: 'wrapper_821',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'lab_equip_inv_temp',
                id: 'lab_equip_inv_temp',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: true,
                required: true,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Storage Temperature (°C)',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_119',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_779',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'lab_equip_inv_name',
                id: 'lab_equip_inv_name',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Cabinet Name',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_463',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_188',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_208',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_778',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'lab_equip_inv_vendor',
                id: 'lab_equip_inv_vendor',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['vendor'],
                      inv_type_perma: 'storage-cabinet',
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Vendor',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_750',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_857',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'lab_equip_inv_cat_no',
                id: 'lab_equip_inv_cat_no',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Catalogue No',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_811',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_136',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'lab_equip_inv_products_url',
                id: 'lab_equip_inv_products_url',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Product Url',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_632',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_157',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_303',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_598',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'lab_equip_inv_no_of_boxes',
                id: 'lab_equip_inv_no_of_boxes',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: true,
                customValidation: [{ regularExpression: '/^d+(.d+)?$/', errorMessage: 'Please type a number' }],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'No of Boxes',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_967',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_216',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'lab_equip_inv_storage',
                id: 'lab_equip_inv_storage',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: true,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Storage Room',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_334',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_585',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_902',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_539',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'lab_equip_inv_desc',
                id: 'lab_equip_inv_desc',
                value: '',
                placeholder: '',
                multiline: true,
                classname: 'form-control  textarea',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Description/Comments',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_41',
            classname: 'col-12',
            wrapperType: 'BOX',
            id: 'wrapper_45',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },

  // storage cabinets master search
  {
    jsonId: 24,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_661',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_986',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'Cabinet_name',
                id: 'Cabinet_name',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Storage Cabinet Type',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_519',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_252',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'storage_cabinet_temp',
                id: 'storage_cabinet_temp',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['st_temp'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Storage Temperature(°C)',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_656',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_306',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  // Shelves master search
  {
    jsonId: 26,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_661',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_986',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'lab_equipments_name',
                id: 'lab_equipments_name',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Shelf Type',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_519',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_252',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'lab_equipments_temp',
                id: 'lab_equipments_temp',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['st_temp'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Storage Temperature(°C)',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_656',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_306',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },

  //add new Shelf master
  {
    jsonId: 27,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [
          {
            type: 'text',
            name: 'lab_equipments_name',
            id: 'labEquipmentsName',
            value: '',
            placeholder: '',
            multiline: false,
            classname: 'form-control ',
            regularExpression: '',
            disabled: false,
            required: true,
            errorMessage: { noData: 'This field is required', regexFail: '' },
            validationButtonType: 'submit',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: '',
            requiredErrorMessage: 'This field is required',
            showInputLabel: true,
            labelContent: 'Shelf Type',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_457',
        classname: 'col-12 mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_308',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [
          {
            type: 'dropdown',
            showBlankInPlaceholder: true,
            blankDataList: ['""', 'undefined', 'null'],
            name: 'lab_equipments_temp',
            id: 'labEquipmentsTemp',
            validationButtonType: 'submit',
            classname: '',
            required: true,
            apiEndPoints: [
              {
                type: 'init',
                method: 'post',
                url: 'masterdata',
                payload: {
                  data_types: ['st_temp'],
                },
              },
            ],
            options: [],
            multiple: false,
            isClearable: false,
            isStatus: false,
            search: false,
            singleSelect: true,
            showLabel: 'value',
            errorMessage: { noData: 'This field is required' },
            requiredErrorMessage: 'This field is required',
            value: '',
            imageUrl: '',
            disabled: false,
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Storage Temperature(°C)',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_120',
        classname: 'col-12 mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_994',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [
          {
            type: 'text',
            name: 'lab_equipments_desc',
            id: 'labEquipmentsDesc',
            value: '',
            placeholder: '',
            multiline: true,
            classname: 'form-control  textarea',
            regularExpression: '',
            disabled: false,
            required: true,
            errorMessage: { noData: 'This field is required', regexFail: '' },
            validationButtonType: 'submit',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: '',
            requiredErrorMessage: 'This field is required',
            showInputLabel: true,
            labelContent: 'Description',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_775',
        classname: 'col-12 mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_925',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },

  //add new inventory Shelves
  {
    jsonId: 28,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_753',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_260',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'lab_equip_inv_master_name',
                id: 'lab_equip_inv_master_name',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata/master-type-list',
                    payload: ['shelves'],
                  },
                  {
                    type: 'change',
                    method: '',
                    Mapto: [
                      {
                        id: 'main.wrapper_260.wrapper_779.lab_equip_inv_temp',
                        type: 'text',
                        urlParameterValues: { storage_temp: null },
                      },
                    ],
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Select Shelf Type',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_228',
            classname: 'col-3 ',
            wrapperType: 'BOX',
            id: 'wrapper_821',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'lab_equip_inv_temp',
                id: 'lab_equip_inv_temp',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: true,
                required: true,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Storage Temperature (°C)',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_119',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_779',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'lab_equip_inv_name',
                id: 'lab_equip_inv_name',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Shelf Name',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_463',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_188',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_208',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_778',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'lab_equip_inv_vendor',
                id: 'lab_equip_inv_vendor',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['vendor'],
                      inv_type_perma: 'shelves',
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Vendor',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_750',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_857',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'lab_equip_inv_cat_no',
                id: 'lab_equip_inv_cat_no',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Catalogue No',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_811',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_136',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'lab_equip_inv_products_url',
                id: 'lab_equip_inv_products_url',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Product Url',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_632',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_157',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_303',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_598',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'lab_equip_inv_no_of_boxes',
                id: 'lab_equip_inv_no_of_boxes',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: true,
                customValidation: [{ regularExpression: '/^d+(.d+)?$/', errorMessage: 'Please type a number' }],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'No of Boxes',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_967',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_216',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'lab_equip_inv_storage',
                id: 'lab_equip_inv_storage',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: true,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Storage Room',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_334',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_585',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_902',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_539',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'lab_equip_inv_desc',
                id: 'lab_equip_inv_desc',
                value: '',
                placeholder: '',
                multiline: true,
                classname: 'form-control  textarea',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Description/Comments',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_41',
            classname: 'col-12',
            wrapperType: 'BOX',
            id: 'wrapper_45',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },

  // shelf inventory filter
  {
    jsonId: 29,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_817',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_409',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'inv_name',
                id: 'inv_name',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Shelf Name',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_519',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_252',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'inv_temp',
                id: 'inv_temp',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['st_temp'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Storage Temperature',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_128',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_929',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'inv_vendor',
                id: 'inv_vendor',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['vendor'],
                      inv_type_perma: 'shelves',
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Vendor',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_312',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_481',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },

  // Assay Kits master search
  {
    jsonId: 30,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_6641',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_9846',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'inv_name',
                id: 'kit_name',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Assay Kit  Name',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_5219',
            classname: 'col-12',
            wrapperType: 'BOX',
            id: 'wrapper_2352',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  //Add new Assay Kit Master
  {
    jsonId: 31,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [
          {
            type: 'text',
            name: 'name',
            id: 'assay_kit_name',
            value: '',
            placeholder: '',
            multiline: false,
            classname: 'form-control ',
            regularExpression: '',
            disabled: false,
            required: true,
            errorMessage: { noData: 'This field is required', regexFail: '' },
            requiredErrorMessage: 'This field is required',
            validationButtonType: 'submit',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Assay Kit Name',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_584',
        classname: 'form-group',
        wrapperType: 'BOX',
        id: 'wrapper_299',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [
          {
            type: 'text',
            name: 'desc',
            id: 'assay_kit_desc',
            value: '',
            placeholder: '',
            multiline: true,
            classname: 'form-control textarea',
            regularExpression: '',
            disabled: false,
            required: true,
            errorMessage: { noData: 'This field is required', regexFail: '' },
            requiredErrorMessage: 'This field is required',
            validationButtonType: 'submit',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Description',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_946',
        classname: 'form-group',
        wrapperType: 'BOX',
        id: 'wrapper_547',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  //add new Agar Plate
  {
    jsonId: 32,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [
          {
            type: 'dropdown',
            showBlankInPlaceholder: true,
            blankDataList: ['""', 'undefined', 'null'],
            name: 'agar_plate_type',
            id: 'agarPlateType',
            validationButtonType: 'submit',
            classname: '',
            required: true,
            apiEndPoints: [
              {
                type: 'init',
                method: 'post',
                url: 'inventory-plasmids/inventory-sub-types',
                payload: {
                  perma: ['plasmids'],
                },
              },
            ],
            options: [],
            multiple: false,
            isClearable: false,
            isStatus: false,
            search: false,
            singleSelect: true,
            showLabel: 'value',
            errorMessage: { noData: 'This field is required' },
            requiredErrorMessage: 'This field is required',
            value: '',
            imageUrl: '',
            disabled: false,
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Item Type',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_935',
        classname: 'form-group',
        wrapperType: 'BOX',
        id: 'wrapper_2176',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [
          {
            type: 'text',
            name: 'agar_plate',
            id: 'agarPlate',
            value: '',
            placeholder: '',
            multiline: false,
            classname: 'form-control ',
            regularExpression: '',
            disabled: false,
            required: true,
            errorMessage: { noData: 'This field is required', regexFail: '' },
            requiredErrorMessage: 'This field is required',
            validationButtonType: 'submit',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Item Name',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_584',
        classname: 'form-group',
        wrapperType: 'BOX',
        id: 'wrapper_2919',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [
          {
            type: 'text',
            name: 'description',
            id: 'description',
            value: '',
            placeholder: '',
            multiline: true,
            classname: 'form-control textarea',
            regularExpression: '',
            disabled: false,
            required: true,
            errorMessage: { noData: 'This field is required', regexFail: '' },
            requiredErrorMessage: 'This field is required',
            validationButtonType: 'submit',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Description',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_946',
        classname: 'form-group',
        wrapperType: 'BOX',
        id: 'wrapper_5427',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  // Agar-plate master search
  {
    jsonId: 33,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_661',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_4986',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'agar_plate_type',
                id: 'agar_plate_type',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'inventory-plasmids/inventory-sub-types',
                    payload: {
                      perma: ['plasmids'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Agar-Plate Type',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_656',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_3206',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'agar_plate',
                id: 'agar_plate',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Agar-plate Name',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_519',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_2152',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  //add new plasticware master
  {
    jsonId: 34,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [
          {
            type: 'text',
            name: 'name',
            id: 'plasticwareName',
            value: '',
            placeholder: '',
            multiline: false,
            classname: 'form-control ',
            regularExpression: '',
            disabled: false,
            required: true,
            errorMessage: { noData: 'This field is required', regexFail: '' },
            requiredErrorMessage: 'This field is required',
            validationButtonType: 'submit',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Plasticware Name',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_584',
        classname: 'form-group',
        wrapperType: 'BOX',
        id: 'wrapper_2909',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [
          {
            type: 'dropdown',
            name: 'usability_type',
            id: 'usabilityType',
            validationButtonType: 'submit',
            classname: '',
            required: true,
            apiEndPoints: [],
            options: [
              { id: 1, value: 'Disposible' },
              { id: 2, value: 'Reusable' },
            ],
            multiple: false,
            isClearable: false,
            isStatus: false,
            search: false,
            singleSelect: true,
            showLabel: 'value',
            errorMessage: { noData: 'This field is required' },
            requiredErrorMessage: 'This field is required',
            value: '',
            imageUrl: '',
            disabled: false,
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Usability Type',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_935',
        classname: 'form-group',
        wrapperType: 'BOX',
        id: 'wrapper_2076',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },

      {
        control: [
          {
            type: 'text',
            name: 'desc',
            id: 'description',
            value: '',
            placeholder: '',
            multiline: true,
            classname: 'form-control textarea',
            regularExpression: '',
            disabled: false,
            required: true,
            errorMessage: { noData: 'This field is required', regexFail: '' },
            requiredErrorMessage: 'This field is required',
            validationButtonType: 'submit',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Description',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_946',
        classname: 'form-group',
        wrapperType: 'BOX',
        id: 'wrapper_5027',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  //plasticware master search
  {
    jsonId: 35,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_661',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_4186',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'usability_type',
                id: 'usabilityType',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [],
                options: [
                  { id: 0, value: 'All' },
                  { id: 1, value: 'Disposible' },
                  { id: 2, value: 'Reusable' },
                ],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Usability Type',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_656',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_3106',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'plasticware_name',
                id: 'plasticwareName',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Plasticware Name',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_519',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_2112',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  //add new glassware master
  {
    jsonId: 36,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [
          {
            type: 'text',
            name: 'name',
            id: 'glasswareName',
            value: '',
            placeholder: '',
            multiline: false,
            classname: 'form-control ',
            regularExpression: '',
            disabled: false,
            required: true,
            errorMessage: { noData: 'This field is required', regexFail: '' },
            requiredErrorMessage: 'This field is required',
            validationButtonType: 'submit',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Glassware Name',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_584',
        classname: 'form-group',
        wrapperType: 'BOX',
        id: 'wrapper_2989',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [
          {
            type: 'dropdown',
            showBlankInPlaceholder: true,
            blankDataList: ['""', 'undefined', 'null'],
            name: 'usability_type',
            id: 'usabilityType',
            validationButtonType: 'submit',
            classname: '',
            required: true,
            apiEndPoints: [],
            options: [
              { id: 1, value: 'Disposible' },
              { id: 2, value: 'Reusable' },
            ],
            multiple: false,
            isClearable: false,
            isStatus: false,
            search: false,
            singleSelect: true,
            showLabel: 'value',
            errorMessage: { noData: 'This field is required' },
            requiredErrorMessage: 'This field is required',
            value: '',
            imageUrl: '',
            disabled: false,
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Usability Type',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_935',
        classname: 'form-group',
        wrapperType: 'BOX',
        id: 'wrapper_2096',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },

      {
        control: [
          {
            type: 'text',
            name: 'desc',
            id: 'description',
            value: '',
            placeholder: '',
            multiline: true,
            classname: 'form-control textarea',
            regularExpression: '',
            disabled: false,
            required: true,
            errorMessage: { noData: 'This field is required', regexFail: '' },
            requiredErrorMessage: 'This field is required',
            validationButtonType: 'submit',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Description',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_946',
        classname: 'form-group',
        wrapperType: 'BOX',
        id: 'wrapper_5007',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  //glassware master search
  {
    jsonId: 37,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_661',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_4186',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'usability_type',
                id: 'usabilityType',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [],
                options: [
                  { id: 0, value: 'All' },
                  { id: 1, value: 'Disposible' },
                  { id: 2, value: 'Reusable' },
                ],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Usability Type',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_656',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_3306',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'glassware_name',
                id: 'glasswareName',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Glassware Name',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_519',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_262',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  // Cell Culture Media,Antibiotics &Agar Plates master search
  {
    jsonId: 39,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_661',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_986',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'item_type',
                id: 'item_type',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [],
                options: [
                  { id: 0, value: 'All' },
                  { id: 21, value: 'Cell Culture Media' },
                  { id: 22, value: 'Antibiotics' },
                  { id: 23, value: 'Agar Plates' },
                ],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Item Type',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_656',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_306',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'item_name',
                id: 'item_name',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Item Name',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_519',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_252',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  //add new Cell-Culture-Media , antibiotics & agar plates  master
  {
    jsonId: 40,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [
          {
            type: 'dropdown',
            showBlankInPlaceholder: true,
            blankDataList: ['""', 'undefined', 'null'],
            name: 'item_type',
            id: 'itemType',
            validationButtonType: 'submit',
            classname: '',
            required: true,
            apiEndPoints: [
              {
                type: 'init',
                method: 'post',
                url: 'inventory-plasmids/inventory-sub-types',
                payload: {
                  perma: ['cell-culture-media-antibiotics-agar-plates'],
                },
              },
            ],
            options: [],
            multiple: false,
            isClearable: false,
            isStatus: false,
            search: false,
            singleSelect: true,
            showLabel: 'value',
            errorMessage: {
              noData: 'This field is required',
            },
            requiredErrorMessage: 'This field is required',
            value: '',
            imageUrl: '',
            disabled: false,
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Item Type',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_935',
        classname: 'form-group',
        wrapperType: 'BOX',
        id: 'wrapper_276',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [
          {
            type: 'text',
            name: 'name',
            id: 'itemName',
            value: '',
            placeholder: '',
            multiline: false,
            classname: 'form-control ',
            regularExpression: '',
            disabled: false,
            required: true,
            errorMessage: {
              noData: 'This field is required',
              regexFail: '',
            },
            requiredErrorMessage: 'This field is required',
            validationButtonType: 'submit',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Item Name',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_584',
        classname: 'form-group',
        wrapperType: 'BOX',
        id: 'wrapper_299',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [
          {
            type: 'text',
            name: 'desc',
            id: 'description',
            value: '',
            placeholder: '',
            multiline: true,
            classname: 'form-control textarea',
            regularExpression: '',
            disabled: false,
            required: true,
            errorMessage: {
              noData: 'This field is required',
              regexFail: '',
            },
            requiredErrorMessage: 'This field is required',
            validationButtonType: 'submit',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Description',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_946',
        classname: 'form-group',
        wrapperType: 'BOX',
        id: 'wrapper_547',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  // Gels,Buffer and Lab Reagents master search
  {
    jsonId: 41,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_661',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_986',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'item_type',
                id: 'item_type',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [],
                options: [
                  { id: 0, value: 'All' },
                  { id: 24, value: 'Gels' },
                  { id: 25, value: 'Buffers' },
                  { id: 26, value: 'Lab Reagents' },
                ],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Item Type',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_656',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_306',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'item_name',
                id: 'item_name',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Item Name',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_519',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_252',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  //add new Gels,Buffer and Lab Reagents master
  {
    jsonId: 42,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [
          {
            type: 'dropdown',
            showBlankInPlaceholder: true,
            blankDataList: ['""', 'undefined', 'null'],
            name: 'item_type',
            id: 'itemType',
            validationButtonType: 'submit',
            classname: '',
            required: true,
            apiEndPoints: [
              {
                type: 'init',
                method: 'post',
                url: 'inventory-plasmids/inventory-sub-types',
                payload: {
                  perma: ['gels-buffers-lab-reagents'],
                },
              },
            ],
            options: [],
            multiple: false,
            isClearable: false,
            isStatus: false,
            search: false,
            singleSelect: true,
            showLabel: 'value',
            errorMessage: { noData: 'This field is required' },
            requiredErrorMessage: 'This field is required',
            value: '',
            imageUrl: '',
            disabled: false,
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Item Type',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_935',
        classname: 'form-group',
        wrapperType: 'BOX',
        id: 'wrapper_276',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [
          {
            type: 'text',
            name: 'name',
            id: 'itemName',
            value: '',
            placeholder: '',
            multiline: false,
            classname: 'form-control ',
            regularExpression: '',
            disabled: false,
            required: true,
            errorMessage: { noData: 'This field is required', regexFail: '' },
            requiredErrorMessage: 'This field is required',
            validationButtonType: 'submit',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Item Name',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_584',
        classname: 'form-group',
        wrapperType: 'BOX',
        id: 'wrapper_299',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [
          {
            type: 'text',
            name: 'desc',
            id: 'description',
            value: '',
            placeholder: '',
            multiline: true,
            classname: 'form-control textarea',
            regularExpression: '',
            disabled: false,
            required: true,
            errorMessage: { noData: 'This field is required', regexFail: '' },
            requiredErrorMessage: 'This field is required',
            validationButtonType: 'submit',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Description',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_946',
        classname: 'form-group',
        wrapperType: 'BOX',
        id: 'wrapper_547',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  //add new inventory Assay kit
  {
    jsonId: 43,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_753',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_260',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'inv_master',
                id: 'inv_master',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata/master-type-list',
                    payload: ['assay-kits'],
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Assay Kit',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_463',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_188',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'inv_item_quantity',
                id: 'inv_item_quantity',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                requiredErrorMessage: 'Please select total no: of units',
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [
                  { regularExpression: '/^[1-9][0-9]*$/', errorMessage: 'Please type a valid non zero integer' },
                ],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'No of Units',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_8415',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_1371',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_208',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_778',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'vendor',
                id: 'vendor',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['vendor'],
                      inv_type_perma: 'assay-kits',
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Vendor',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_750',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_857',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'catelogue_no',
                id: 'catelogue_no',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Catalogue No',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_811',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_136',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'product_url',
                id: 'product_url',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Product Url',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_632',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_157',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_303',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_598',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'biosafety_level',
                id: 'dropDown_206',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['bio_level'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: { id: 2, value: 'BSL-2' },
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Biosafety Level',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_8412',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_1368',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'radio',
                name: 'is_expire',
                id: 'is_expire',
                value: 1,
                required: false,
                classname: 'form-check-wrap',
                errorMessage: { noData: '' },
                options: [
                  { key: 1, label: 'Yes' },
                  { key: 0, label: 'No' },
                ],
                functionName: '',
                readonly: false,
                validationButtonType: 'submit',
                disabled: false,
                wrapperClassName: 'form-check form-check-inline',
                showInputLabel: true,
                labelContent: 'Expiry Date',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
                controlPoints: [
                  {
                    type: 'change',
                    value: 1,
                    Mapto: [
                      {
                        id: 'main.wrapper_598.wrapper_959',
                        type: 'showField',
                      },
                    ],
                  },
                  {
                    type: 'change',
                    value: 0,
                    Mapto: [
                      {
                        id: 'main.wrapper_598.wrapper_959',
                        type: 'hideField',
                      },
                    ],
                  },
                ],
              },
            ],
            type: '',
            name: 'wrapper_156',
            classname: 'col-2 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_358',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'expiry_date',
                id: 'expiry_date',
                validationButtonType: 'submit',
                readonly: false,
                range: false,
                value: CURRENTDATE,
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: CURRENTDATE,
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                required: true,
                classname: '',
                errorMessage: { noData: 'This field is required', regexFail: 'Date is not valid!!' },
                errorClass: 'errorMessage',
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-GB',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: '',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_763',
            classname: 'col-2 date-no-label',
            wrapperType: 'BOX',
            id: 'wrapper_959',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
            disableValue: {
              expiry_date: null,
            },
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'storage_temp',
                id: 'storage_temp',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: { data_types: ['st_temp'] },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Storage Temp (°C)',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_290',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_654',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'date_of_storage',
                id: 'date_of_storage',
                validationButtonType: 'submit',
                readonly: false,
                range: false,
                value: 'CURRENTDATE',
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: 'CURRENTDATE',
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                required: true,
                classname: '',
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: 'Date is not valid!!',
                },
                errorClass: 'errorMessage',
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-GB',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: 'Date of Storage',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_7633',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_95989',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
            disableValue: {
              expiry_date: '',
            },
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  //add new inventory Cell Culture (Agar Plates,Cell Culture Media & Antibiotics)
  {
    jsonId: 44,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_529',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_10',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                name: 'inv_type',
                id: 'inv_type',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'inventory-plasmids/inventory-sub-types',
                    payload: {
                      perma: ['cell-culture-media-antibiotics-agar-plates'],
                    },
                  },
                  {
                    type: 'change',
                    method: 'post',
                    Mapto: [
                      {
                        id: 'main.wrapper_10.wrapper_656.inv_master',
                        displayKey: 'value',
                        urlParameterValues: { value: null },
                      },
                    ],
                    url: 'masterdata/master-type-list',
                  },
                  {
                    type: 'change',
                    method: 'post',
                    url: 'masterdata',
                    Mapto: [
                      {
                        id: 'main.wrapper_171.wrapper_857.vendor',
                        displayKey: 'value',
                        urlParameterValues: { value: null },
                      },
                    ],
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: {
                  id: 21,
                  value: 'Cell Culture Media',
                },
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Item Type',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
                controlPoints: [
                  {
                    type: 'change',
                    value: {
                      id: 23,
                      value: 'Agar Plates',
                    },
                    Mapto: [
                      {
                        id: 'main.wrapper_10.wrapper_358', //source
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_598.wrapper_1334', //shelf life
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_10.wrapper_136', //form agar
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_10.wrapper_6588', //other form
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_10.wrapper_1368', //conc
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_10.wrapper_1369', //unit
                        type: 'hideField',
                      },
                    ],
                  },
                  {
                    type: 'change',
                    value: {
                      id: 21,
                      value: 'Cell Culture Media',
                    },
                    Mapto: [
                      {
                        id: 'main.wrapper_10.wrapper_358', //source
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_171', //vendor
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_598.wrapper_1334', //shelf life
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_10.wrapper_136', //form agar
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_10.wrapper_6588', //other form
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_10.wrapper_1368', //conc
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_10.wrapper_1369', //unit
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_10.wrapper_582', //i house
                        type: 'hideField',
                      },
                    ],
                  },
                  {
                    type: 'change',
                    value: {
                      id: 22,
                      value: 'Antibiotics',
                    },
                    Mapto: [
                      {
                        id: 'main.wrapper_10.wrapper_358', //source
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_171', //vendor
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_598.wrapper_1334', //shelf life
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_10.wrapper_136', //form agar
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_10.wrapper_6588', //other form
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_10.wrapper_1368', //conc
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_10.wrapper_1369', //unit
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_10.wrapper_582', //in house
                        type: 'hideField',
                      },
                    ],
                  },
                ],
              },
            ],
            type: '',
            name: 'wrapper_290',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_654',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'dropdown',
                name: 'inv_master',
                id: 'inv_master',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Item Name',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_291',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_656',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'form_agar_plate',
                id: 'form_agar_plate',
                value: '10 cm Plate',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: true,
                required: true,
                requiredErrorMessage: 'This field is required',
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Form',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_815',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_136',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                name: 'form_other_type',
                id: 'form_other_type',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [],
                options: [
                  {
                    id: 1,
                    value: 'Liquid',
                  },
                  {
                    id: 2,
                    value: 'Powder',
                  },
                ],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Form',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_292',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_6588',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'no_of_boxes',
                id: 'no_of_boxes',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                requiredErrorMessage: 'This field is required',
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [
                  { regularExpression: /^[1-9][0-9]*$/, errorMessage: 'Please type a valid non zero integer' },
                ],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Unit size',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_8115',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_1368',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'concentration',
                id: 'concentration',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                requiredErrorMessage: 'This field is required',
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [
                  {
                    regularExpression: /^(0\.[1-9]+|[1-9]\d*(\.\d+)?)$/,
                    errorMessage: 'Please type a valid non zero number',
                  },
                ],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Conc (mg/mL) ',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_8113',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_1369',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'inv_item_quantity',
                id: 'inv_item_quantity',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                requiredErrorMessage: 'Select total no: of units',
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [
                  { regularExpression: /^[1-9][0-9]*$/, errorMessage: 'Please type a valid non zero integer' },
                ],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Quantity (No of Units)',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_811',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_13697',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'radio',
                name: 'source',
                id: 'source',
                value: {
                  id: 1,
                  value: 'In House',
                },
                required: false,
                classname: 'form-check-wrap',
                errorMessage: { noData: '' },
                options: [
                  {
                    key: {
                      id: 1,
                      value: 'In House',
                    },
                    label: 'In House',
                  },
                  {
                    key: {
                      id: 2,
                      value: 'Commercial',
                    },
                    label: 'Commercial',
                  },
                ],
                functionName: '',
                readonly: false,
                validationButtonType: 'submit',
                disabled: false,
                wrapperClassName: 'form-check form-check-inline',
                showInputLabel: true,
                labelContent: 'Source',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
                controlPoints: [
                  {
                    type: 'change',
                    value: {
                      id: 1,
                      value: 'In House',
                    },
                    Mapto: [
                      {
                        id: 'main.wrapper_10.wrapper_582',
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_171',
                        type: 'hideField',
                      },
                    ],
                  },
                  {
                    type: 'change',
                    value: {
                      id: 2,
                      value: 'Commercial',
                    },
                    Mapto: [
                      {
                        id: 'main.wrapper_10.wrapper_582',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_171',
                        type: 'showField',
                      },
                    ],
                  },
                ],
              },
            ],
            type: '',
            name: 'wrapper_156',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_358',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'inhouse_id',
                id: 'inhouse_id',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'In House Source ID',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_525',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_582',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },

      {
        control: [],
        type: '',
        name: 'wrapper_922',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_171',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                name: 'vendor',
                id: 'vendor',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Vendor',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_750',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_857',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'catelogue_no',
                id: 'catelogue_no',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                requiredErrorMessage: 'This field is required',
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Catalogue No',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_782',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_475',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'product_url',
                id: 'product_url',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                requiredErrorMessage: 'This field is required',
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Product URL',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_867',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_215',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_303',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_598',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                name: 'biosafety_level',
                id: 'dropDown_206',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['bio_level'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: { id: 2, value: 'BSL-2' },
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Biosafety Level',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_8412',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_1368',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'shelf_life_months',
                id: 'shelf_life_months',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                requiredErrorMessage: 'This field is required',
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [
                  {
                    regularExpression: /^(0\.[1-9]+|[1-9]\d*(\.\d+)?)$/,
                    errorMessage: 'Please type a valid non zero number',
                  },
                ],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Shelf Life (Months)',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_8412',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_1334',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'radio',
                name: 'is_expire',
                label: { type: 'label', name: '', classname: '', for: '', required: false, wrapperClassName: '' },
                id: 'is_expire',
                value: 1,
                required: false,
                classname: 'form-check-wrap',
                errorMessage: { noData: '' },
                options: [
                  { key: 1, label: 'Yes' },
                  { key: 0, label: 'No' },
                ],
                functionName: '',
                readonly: false,
                validationButtonType: 'submit',
                disabled: false,
                wrapperClassName: 'form-check form-check-inline',
                showInputLabel: true,
                labelContent: 'Expiry Date',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
                controlPoints: [
                  {
                    type: 'change',
                    value: 1,
                    Mapto: [
                      {
                        id: 'main.wrapper_598.wrapper_959',
                        type: 'showField',
                      },
                    ],
                  },
                  {
                    type: 'change',
                    value: 0,
                    Mapto: [
                      {
                        id: 'main.wrapper_598.wrapper_959',
                        type: 'hideField',
                      },
                    ],
                  },
                ],
              },
            ],
            type: '',
            name: 'wrapper_156',
            classname: 'col-2 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_358',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'expiry_date',
                id: 'expiry_date',
                validationButtonType: 'submit',
                readonly: false,
                range: false,
                value: CURRENTDATE,
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: CURRENTDATE,
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                required: true,
                classname: '',
                errorMessage: { noData: 'This field is required', regexFail: 'Date is not valid!!' },
                errorClass: 'errorMessage',
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-GB',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: '',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_763',
            classname: 'col-2 date-no-label',
            wrapperType: 'BOX',
            id: 'wrapper_959',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
            disableValue: {
              expiry_date: null,
            },
          },
          {
            control: [
              {
                type: 'dropdown',
                name: 'storage_temp',
                id: 'storage_temp',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['st_temp'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Storage Temp (°C)',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_290',
            classname: 'col-2 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_654',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'date_of_storage',
                id: 'date_of_storage',
                validationButtonType: 'submit',
                readonly: false,
                range: false,
                value: CURRENTDATE,
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: CURRENTDATE,
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                // regularExpression:
                //   '/^(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4}(s+tos+(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4}|(,s*(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4})*)?$/',
                required: false,
                classname: '',
                errorMessage: { noData: 'This field is required', regexFail: 'Date is not valid!!' },
                errorClass: 'errorMessage',
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-GB',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: 'Date of Storage',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_7633',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_95989',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
            disableValue: {
              expiry_date: '',
            },
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
    showClass: true,
  },

  //add new Instruments master
  {
    jsonId: 45,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [
          {
            type: 'text',
            name: 'name',
            id: 'instrumentName',
            value: '',
            placeholder: '',
            multiline: false,
            classname: 'form-control ',
            regularExpression: '',
            disabled: false,
            required: true,
            errorMessage: { noData: 'This field is required', regexFail: '' },
            requiredErrorMessage: 'This field is required',
            validationButtonType: 'submit',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Instrument Name',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_584',
        classname: 'form-group',
        wrapperType: 'BOX',
        id: 'wrapper_2989',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [
          {
            type: 'dropdown',
            showBlankInPlaceholder: true,
            blankDataList: ['""', 'undefined', 'null'],
            name: 'status',
            id: 'statusType',
            validationButtonType: 'submit',
            classname: '',
            required: true,
            apiEndPoints: [],
            options: [
              { id: 1, value: 'Active' },
              { id: 2, value: 'Under Repair' },
              { id: 3, value: 'Retired' },
            ],
            multiple: false,
            isClearable: false,
            isStatus: false,
            search: false,
            singleSelect: true,
            showLabel: 'value',
            errorMessage: { noData: 'This field is required' },
            requiredErrorMessage: 'This field is required',
            value: '',
            imageUrl: '',
            disabled: false,
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Status',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_935',
        classname: 'form-group',
        wrapperType: 'BOX',
        id: 'wrapper_2096',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },

      {
        control: [
          {
            type: 'text',
            name: 'desc',
            id: 'description',
            value: '',
            placeholder: '',
            multiline: true,
            classname: 'form-control textarea',
            regularExpression: '',
            disabled: false,
            required: true,
            errorMessage: { noData: 'This field is required', regexFail: '' },
            requiredErrorMessage: 'This field is required',
            validationButtonType: 'submit',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Description',
            labelClass: 'form-label mandatory',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_946',
        classname: 'form-group',
        wrapperType: 'BOX',
        id: 'wrapper_5007',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  //Instruments master search
  {
    jsonId: 46,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_661',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_4186',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'status_type',
                id: 'statusType',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [],
                options: [
                  { id: 1, value: 'Active' },
                  { id: 2, value: 'Under Repair' },
                  { id: 3, value: 'Retired' },
                ],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Status',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_656',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_3306',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'instrument_name',
                id: 'instrumentName',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Instrument Name',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_519',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_262',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },

  //add new inventory Cell Culture (Gels , Buffers & Lab Reagents)
  {
    jsonId: 47,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_529',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_10',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                name: 'inv_type',
                id: 'inv_type',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'inventory-plasmids/inventory-sub-types',
                    payload: {
                      perma: ['gels-buffers-lab-reagents'],
                    },
                  },
                  {
                    type: 'change',
                    method: 'post',
                    Mapto: [
                      {
                        id: 'main.wrapper_10.wrapper_656.inv_master',
                        displayKey: 'value',
                        urlParameterValues: { value: null },
                      },
                    ],
                    url: 'masterdata/master-type-list',
                  },
                  {
                    type: 'change',
                    method: 'post',
                    url: 'masterdata',
                    Mapto: [
                      {
                        id: 'main.wrapper_171.wrapper_857.vendor',
                        displayKey: 'value',
                        urlParameterValues: { value: null },
                      },
                    ],
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: {
                  id: 24,
                  value: 'Gels',
                },
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Item Type',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
                controlPoints: [
                  {
                    type: 'change',
                    value: {
                      id: 24,
                      value: 'Gels',
                    },
                    Mapto: [
                      {
                        id: 'main.wrapper_10.wrapper_136', //box
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_10.wrapper_1367', //wells
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_10.wrapper_1368', //valume
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_10.wrapper_6544', //form
                        type: 'hideField',
                      },
                    ],
                  },
                  {
                    type: 'change',
                    value: {
                      id: 26,
                      value: 'Lab Reagents',
                    },
                    Mapto: [
                      {
                        id: 'main.wrapper_10.wrapper_136', //box
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_10.wrapper_1367', //wells
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_10.wrapper_1368', //valume
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_10.wrapper_6544', //form
                        type: 'showField',
                      },
                    ],
                  },
                  {
                    type: 'change',
                    value: {
                      id: 25,
                      value: 'Buffers',
                    },
                    Mapto: [
                      {
                        id: 'main.wrapper_10.wrapper_136', //box
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_10.wrapper_1367', //wells
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_10.wrapper_1368', //valume
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_10.wrapper_6544', //form
                        type: 'showField',
                      },
                    ],
                  },
                ],
              },
            ],
            type: '',
            name: 'wrapper_290',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_654',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'dropdown',
                name: 'inv_master',
                id: 'inv_master',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Item Name',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_291',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_656',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'inv_item_quantity',
                id: 'inv_item_quantity',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                requiredErrorMessage: 'Select total no: of units',
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [
                  { regularExpression: /^[1-9][0-9]*$/, errorMessage: 'Please type a valid non zero integer' },
                ],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Quantity(No of Units)',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_811',
            classname: 'col-2 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_13656',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'no_of_boxes',
                id: 'no_of_boxes',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                requiredErrorMessage: 'This field is required',
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [
                  { regularExpression: /^[1-9][0-9]*$/, errorMessage: 'Please type a valid non zero integer' },
                ],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Quantity(No of boxes)',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_811',
            classname: 'col-2 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_136',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'inv_item_wells',
                id: 'inv_item_wells',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                requiredErrorMessage: 'This field is required',
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [
                  { regularExpression: /^[1-9][0-9]*$/, errorMessage: 'Please type a valid non zero integer' },
                ],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Wells',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_811',
            classname: 'col-2 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_1367',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                name: 'form_other_type',
                id: 'form_other_type',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [],
                options: [
                  {
                    id: 1,
                    value: 'Liquid',
                  },
                  {
                    id: 2,
                    value: 'Powder',
                  },
                ],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Form',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_292',
            classname: 'col-2 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_6544',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'inv_item_volume',
                id: 'inv_item_volume',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                requiredErrorMessage: 'This field is required',
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [
                  {
                    regularExpression: /^(0\.[1-9]+|[1-9]\d*(\.\d+)?)$/,
                    errorMessage: 'Please type a valid non zero number',
                  },
                ],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Volume (mL)',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_811',
            classname: 'col-2 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_1368',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },

      {
        control: [],
        type: '',
        name: 'wrapper_922',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_171',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                name: 'vendor',
                id: 'vendor',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['vendor'],
                      inv_type_perma: 'gels',
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Vendor',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_750',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_857',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'catelogue_no',
                id: 'catelogue_no',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                requiredErrorMessage: 'This field is required',
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Catalogue No',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_782',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_475',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'product_url',
                id: 'product_url',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                requiredErrorMessage: 'This field is required',
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Product URL',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_867',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_215',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_303',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_598',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                name: 'biosafety_level',
                id: 'dropDown_206',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['bio_level'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: { id: 2, value: 'BSL-2' },
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Biosafety Level',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_8412',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_1368',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'radio',
                name: 'is_expire',
                label: { type: 'label', name: '', classname: '', for: '', required: false, wrapperClassName: '' },
                id: 'is_expire',
                value: 1,
                required: false,
                classname: 'form-check-wrap',
                errorMessage: { noData: '' },
                options: [
                  { key: 1, label: 'Yes' },
                  { key: 0, label: 'No' },
                ],
                functionName: '',
                readonly: false,
                validationButtonType: 'submit',
                disabled: false,
                wrapperClassName: 'form-check form-check-inline',
                showInputLabel: true,
                labelContent: 'Expiry Date',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
                controlPoints: [
                  {
                    type: 'change',
                    value: 1,
                    Mapto: [
                      {
                        id: 'main.wrapper_598.wrapper_959',
                        type: 'showField',
                      },
                    ],
                  },
                  {
                    type: 'change',
                    value: 0,
                    Mapto: [
                      {
                        id: 'main.wrapper_598.wrapper_959',
                        type: 'hideField',
                      },
                    ],
                  },
                ],
              },
            ],
            type: '',
            name: 'wrapper_156',
            classname: 'col-2 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_358',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'expiry_date',
                id: 'expiry_date',
                validationButtonType: 'submit',
                readonly: false,
                range: false,
                value: CURRENTDATE,
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: CURRENTDATE,
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                required: true,
                classname: '',
                errorMessage: { noData: 'This field is required', regexFail: 'Date is not valid!!' },
                errorClass: 'errorMessage',
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-GB',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: '',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_763',
            classname: 'col-2 date-no-label',
            wrapperType: 'BOX',
            id: 'wrapper_959',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
            disableValue: {
              expiry_date: null,
            },
          },
          {
            control: [
              {
                type: 'dropdown',
                name: 'storage_temp',
                id: 'storage_temp',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['st_temp'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Storage Temp (°C)',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_290',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_654',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'date_of_storage',
                id: 'date_of_storage',
                validationButtonType: 'submit',
                readonly: false,
                range: false,
                value: CURRENTDATE,
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: CURRENTDATE,
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                // regularExpression:
                //   '/^(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4}(s+tos+(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4}|(,s*(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4})*)?$/',
                required: false,
                classname: '',
                errorMessage: { noData: 'This field is required', regexFail: 'Date is not valid!!' },
                errorClass: 'errorMessage',
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-GB',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: 'Date of Storage',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_7633',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_95989',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
            disableValue: {
              expiry_date: '',
            },
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
    showClass: true,
  },

  //storage cabinet inventory filter
  {
    jsonId: 48,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_817',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_409',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'inv_name',
                id: 'cabinetName',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Storage Cabinet Name',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_519',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_252',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'inv_temp',
                id: 'storageCabinetTemp',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['st_temp'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Storage  Temperature',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_128',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_929',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'inv_vendor',
                id: 'cabinetVendor',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['vendor'],
                      inv_type_perma: 'storage-cabinet',
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Vendor',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_312',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_481',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },

  //add new inventory PlasticWare
  {
    jsonId: 49,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_753',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_260',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                name: 'inv_master',
                id: 'inv_master',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata/master-type-list',
                    payload: ['plasticwares'],
                  },
                  {
                    type: 'change',
                    method: '',
                    Mapto: [
                      {
                        id: 'main.wrapper_260.wrapper_1371.usability_type',
                        type: 'text',
                        urlParameterValues: { usability_type: null },
                      },
                    ],
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Plasticware Name',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
                controlPoints: [],
              },
            ],
            type: '',
            name: 'wrapper_463',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_188',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'usability_type',
                id: 'usability_type',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: true,
                required: false,
                requiredErrorMessage: 'This field is required',
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Usability Type',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_8415',
            classname: 'col-2',
            wrapperType: 'BOX',
            id: 'wrapper_1371',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'inv_item_volume',
                id: 'inv_item_volume',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                requiredErrorMessage: 'This field is required',
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [
                  {
                    regularExpression: "/^(0\\.[1-9]+|[1-9]\\d*(\\.\\d+)?)$/",
                    errorMessage: 'Please type a valid non zero number',
                  },
                ],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Volume (mL)',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_8416',
            classname: 'col-2 ',
            wrapperType: 'BOX',
            id: 'wrapper_1371',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'inv_item_quantity',
                id: 'inv_item_quantity',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                requiredErrorMessage: 'Select total no: of units',
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [
                  { regularExpression: '/^[1-9][0-9]*$/', errorMessage: 'Please type a valid non zero integer' },
                ],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Quantity(No of Units)',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_8417',
            classname: 'col-2',
            wrapperType: 'BOX',
            id: 'wrapper_1371',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_208',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_778',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                name: 'vendor',
                id: 'vendor',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['vendor'],
                      inv_type_perma: 'plasticwares',
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Vendor',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_750',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_857',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'catelogue_no',
                id: 'catelogue_no',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Catalogue No',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_811',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_136',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'product_url',
                id: 'product_url',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Product Url',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_632',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_157',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_303',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_598',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                name: 'biosafety_level',
                id: 'biosafety_level',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['bio_level'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: { id: 2, value: 'BSL-2' },
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Biosafety Level',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_967',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_216',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'radio',
                name: 'is_expire',
                label: { type: 'label', name: '', classname: '', for: '', required: false, wrapperClassName: '' },
                id: 'is_expire',
                value: 1,
                required: false,
                classname: 'form-check-wrap',
                errorMessage: { noData: '' },
                options: [
                  { key: 1, label: 'Yes' },
                  { key: 0, label: 'No' },
                ],
                functionName: '',
                readonly: false,
                validationButtonType: 'submit',
                disabled: false,
                wrapperClassName: 'form-check form-check-inline',
                showInputLabel: true,
                labelContent: 'Expiry Date',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
                controlPoints: [
                  {
                    type: 'change',
                    value: 1,
                    Mapto: [
                      {
                        id: 'main.wrapper_598.wrapper_959',
                        type: 'showField',
                      },
                    ],
                  },
                  {
                    type: 'change',
                    value: 0,
                    Mapto: [
                      {
                        id: 'main.wrapper_598.wrapper_959',
                        type: 'hideField',
                      },
                    ],
                  },
                ],
              },
            ],
            type: '',
            name: 'wrapper_156',
            classname: 'col-2 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_358',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'expiry_date',
                id: 'expiry_date',
                validationButtonType: 'submit',
                readonly: false,
                range: false,
                value: CURRENTDATE,
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: CURRENTDATE,
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                required: true,
                classname: '',
                errorMessage: { noData: 'This field is required', regexFail: 'Date is not valid!!' },
                errorClass: 'errorMessage',
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-GB',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: '',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_763',
            classname: 'col-2 date-no-label',
            wrapperType: 'BOX',
            id: 'wrapper_959',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
            disableValue: {
              expiry_date: null,
            },
          },
          {
            control: [
              {
                type: 'dropdown',
                name: 'storage_temp',
                id: 'storage_temp',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: { data_types: ['st_temp'] },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Storage Temp (°C)',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_290',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_654',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'date_of_storage',
                id: 'date_of_storage',
                validationButtonType: 'submit',
                readonly: false,
                range: false,
                value: CURRENTDATE,
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: CURRENTDATE,
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                // regularExpression:
                //   '/^(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4}(s+tos+(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4}|(,s*(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4})*)?$/',
                required: false,
                classname: '',
                errorMessage: { noData: 'This field is required', regexFail: 'Date is not valid!!' },
                errorClass: 'errorMessage',
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-GB',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: 'Date of Storage',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_7633',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_95989',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
            disableValue: {
              expiry_date: '',
            },
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },

  //add new inventory Glassware
  {
    jsonId: 38,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_753',
        classname: 'row ',
        wrapperType: 'BOX',
        id: 'wrapper_260',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'inv_master',
                id: 'inv_master',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata/master-type-list',
                    payload: ['glasswares'],
                  },
                  {
                    type: 'change',
                    method: '',
                    Mapto: [
                      {
                        id: 'main.wrapper_260.wrapper_1371.usability_type',
                        type: 'text',
                        urlParameterValues: { usability_type: null },
                      },
                    ],
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Glassware Name',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_463',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_188',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'usability_type',
                id: 'usability_type',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: true,
                required: false,
                requiredErrorMessage: 'This field is required',
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Usability Type',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_8415',
            classname: 'col-2 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_1371',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'inv_item_volume',
                id: 'inv_item_volume',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                requiredErrorMessage: 'This field is required',
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [
                  {
                    regularExpression: '/^(0.[1-9]+|[1-9]d*(.d+)?)$/',
                    errorMessage: 'Please type a valid non zero number',
                  },
                ],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Volume (mL)',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_8416',
            classname: 'col-2 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_1371',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'inv_item_quantity',
                id: 'inv_item_quantity',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                requiredErrorMessage: 'Please select total no: of units',
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [
                  { regularExpression: '/^[1-9][0-9]*$/', errorMessage: 'Please type a valid non zero integer' },
                ],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Quantity(No of Units)',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_8417',
            classname: 'col-2 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_1371',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_208',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_778',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'vendor',
                id: 'vendor',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['vendor'],
                      inv_type_perma: 'glasswares',
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Vendor',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_750',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_857',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'catelogue_no',
                id: 'catelogue_no',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Catalogue No',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_811',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_136',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'product_url',
                id: 'product_url',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Product Url',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_632',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_157',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_303',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_598',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'biosafety_level',
                id: 'biosafety_level',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['bio_level'],
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: { id: 2, value: 'BSL-2' },
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Biosafety Level',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_967',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_216',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'radio',
                name: 'is_expire',
                label: { type: 'label', name: '', classname: '', for: '', required: false, wrapperClassName: '' },
                id: 'is_expire',
                value: 1,
                required: false,
                classname: 'form-check-wrap',
                errorMessage: { noData: '' },
                options: [
                  { key: 1, label: 'Yes' },
                  { key: 0, label: 'No' },
                ],
                functionName: '',
                readonly: false,
                validationButtonType: 'submit',
                disabled: false,
                wrapperClassName: 'form-check form-check-inline',
                showInputLabel: true,
                labelContent: 'Expiry Date',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
                controlPoints: [
                  {
                    type: 'change',
                    value: 1,
                    Mapto: [
                      {
                        id: 'main.wrapper_598.wrapper_959',
                        type: 'showField',
                      },
                    ],
                  },
                  {
                    type: 'change',
                    value: 0,
                    Mapto: [
                      {
                        id: 'main.wrapper_598.wrapper_959',
                        type: 'hideField',
                      },
                    ],
                  },
                ],
              },
            ],
            type: '',
            name: 'wrapper_156',
            classname: 'col-2 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_358',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'expiry_date',
                id: 'expiry_date',
                validationButtonType: 'submit',
                readonly: false,
                range: false,
                value: CURRENTDATE,
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: CURRENTDATE,
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                required: true,
                classname: '',
                errorMessage: { noData: 'This field is required', regexFail: 'Date is not valid!!' },
                errorClass: 'errorMessage',
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-GB',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: '',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_763',
            classname: 'col-2 date-no-label',
            wrapperType: 'BOX',
            id: 'wrapper_959',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
            disableValue: {
              expiry_date: null,
            },
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'storage_temp',
                id: 'storage_temp',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: { data_types: ['st_temp'] },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Storage Temp (°C)',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_290',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_654',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  }, // assay kits inventory filter
  {
    jsonId: 55,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_817',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_409',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'inv_master',
                id: 'inv_master',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata/master-type-list',
                    payload: ['assay-kits'],
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Inventory Master',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_128',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_929',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'vendor',
                id: 'vendor',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['vendor'],
                      inv_type_perma: 'assay-kits',
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Vendor',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_312',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_481',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },

  // glass wares inventory filter
  {
    jsonId: 56,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_817',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_409',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'inv_master',
                id: 'inv_master',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata/master-type-list',
                    payload: ['glasswares'],
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Inventory Master',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_128',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_929',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'vendor',
                id: 'vendor',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['vendor'],
                      inv_type_perma: 'glasswares',
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Vendor',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_312',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_481',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },

  // plastic wares inventory filter
  {
    jsonId: 57,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_817',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_409',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'inv_master',
                id: 'inv_master',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata/master-type-list',
                    payload: ['plasticwares'],
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Inventory Master',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_128',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_929',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'vendor',
                id: 'vendor',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['vendor'],
                      inv_type_perma: 'plasticwares',
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Vendor',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_312',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_481',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  // cell culture media antibiotics and agar plates inventory filter
  {
    jsonId: 58,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_817',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_409',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'perma',
                id: 'perma',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'change',
                    method: 'post',
                    url: 'masterdata',
                    Mapto: [
                      {
                        id: 'main.wrapper_409.wrapper_4816.vendor',
                        displayKey: 'value',
                        urlParameterValues: { value: null },
                      },
                    ],
                  },
                ],
                options: [
                  {
                    id: 0,
                    parent_perma: 'cell-culture-media-antibiotics-agar-plates',
                    value: 'All',
                  },
                  {
                    id: 21,
                    value: 'Cell Culture Media',
                    perma: 'cell-culture-media',
                  },
                  {
                    id: 22,
                    value: 'Antibiotics',
                    perma: 'antibiotics',
                  },
                  {
                    id: 23,
                    value: 'Agar Plates',
                    perma: 'agar-plates',
                  },
                ],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: {
                  id: 0,
                  parent_perma: 'cell-culture-media-antibiotics-agar-plates',
                  value: 'All',
                },
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Item Type',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_128',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_929',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'vendor',
                id: 'vendor',
                validationButtonType: '',
                classname: '',
                required: false,
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Vendor',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_312',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_4816',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  // gels buffers lab reagents inventory filter
  {
    jsonId: 59,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_817',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_409',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'perma',
                id: 'perma',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'change',
                    method: 'post',
                    url: 'masterdata',
                    Mapto: [
                      {
                        id: 'main.wrapper_409.wrapper_4816.vendor',
                        displayKey: 'value',
                        urlParameterValues: { value: null },
                      },
                    ],
                  },
                ],
                options: [
                  {
                    id: 0,
                    value: 'All',
                    parent_perma: 'gels-buffers-lab-reagents',
                  },
                  {
                    id: 24,
                    value: 'Gels',
                    perma: 'gels',
                  },
                  {
                    id: 25,
                    value: 'Buffers',
                    perma: 'buffers',
                  },
                  {
                    id: 26,
                    value: 'Lab Reagent',
                    perma: 'lab-reagents',
                  },
                ],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: {
                  id: 0,
                  value: 'All',
                  parent_perma: 'gels-buffers-lab-reagents',
                },
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Item Type',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_128',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_929',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'vendor',
                id: 'vendor',
                validationButtonType: '',
                classname: '',
                required: false,
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Vendor',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_312',
            classname: 'col-6',
            wrapperType: 'BOX',
            id: 'wrapper_4816',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  // instrument add inventory
  {
    jsonId: 60,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_215',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_50',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'inv_master_id',
                id: 'inv_master_id',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata/master-type-list',
                    payload: ['instruments'],
                  },
                  {
                    type: 'change',
                    method: '',
                    Mapto: [
                      {
                        id: 'main.wrapper_50.wrapper_451.status',
                        type: 'text',
                        urlParameterValues: { status: null },
                      },
                    ],
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                connectedArrays: [],
                showInputLabel: true,
                labelContent: 'Instruments name',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_406',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_94',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'status',
                id: 'status',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: true,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Status',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_631',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_451',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'serial_no',
                id: 'serial_no',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Serial No',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_372',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_407',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'date_purchase',
                id: 'date_purchase',
                validationButtonType: 'submit',
                range: false,
                value: '',
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: '',
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                regularExpression: '',
                required: true,
                classname: '',
                errorMessage: { noData: 'This field is required', regexFail: 'Date is not valid!!' },
                errorClass: 'errorMessage',
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-GB',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: 'Date of  purchase',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_375',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_467',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'date_installation',
                id: 'date_installation',
                validationButtonType: 'submit',
                range: false,
                value: '',
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: '',
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                regularExpression: '',
                required: true,
                classname: '',
                errorMessage: { noData: 'This field is required', regexFail: 'Date is not valid!!' },
                errorClass: 'errorMessage',
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-GB',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: 'Date of  Installation',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_31',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_651',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'quantity',
                id: 'quantity',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [
                  { regularExpression: '/^[1-9][0-9]*$/', errorMessage: 'Please type a valid non zero integer' },
                ],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Quantity(No  of Units)',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_820',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_356',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_148',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_868',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'vendor',
                id: 'vendor',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['vendor'],
                      inv_type_perma: 'instruments',
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Vendor',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_423',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_737',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'catalogue_no',
                id: 'catalogue_no',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Catalogue No',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_98',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_951',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'product_url',
                id: 'product_url',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Product Url',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_562',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_486',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_151',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_228',
        fields: [
          {
            control: [
              {
                type: 'checkbox',
                id: 'checkbox_9812',
                name: 'cmc_status',
                required: false,
                classname: 'form-check form-check-inline',
                errorMessage: { noData: '' },
                options: [{ key: 'status', label: 'CMC Status' }],
                validationButtonType: 'submit',
                disabled: false,
                wrapperClassName: 'form-checkbox-wrap',
                showInputLabel: true,
                labelContent: '',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
                controlPoints: [
                  {
                    type: 'change',
                    value: true,
                    Mapto: [
                      {
                        id: 'main.wrapper_228.wrapper_196',
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_228.wrapper_273',
                        type: 'showField',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'checkbox',
                id: 'checkbox_981',
                name: 'amc_status',
                required: false,
                classname: 'form-check form-check-inline',
                errorMessage: { noData: '' },
                options: [{ key: 'status', label: 'AMC Status' }],
                validationButtonType: 'submit',
                disabled: false,
                wrapperClassName: 'form-checkbox-wrap',
                showInputLabel: true,
                labelContent: '',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_364',
            classname: 'col-3 mb-3 d-flex align-items-center',
            wrapperType: 'BOX',
            id: 'wrapper_603',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'date_initiation',
                id: 'date_initiation',
                validationButtonType: 'submit',
                range: false,
                value: '',
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: '',
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                errorClass: 'errorMessage',
                required: true,
                classname: '',
                errorMessage: { noData: 'This field is required', regexFail: 'Date is not valid!!' },
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-GB',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: 'Date of  Initiation',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_956',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_196',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'date_initiation_end',
                id: 'date_initiation_end',
                validationButtonType: 'submit',
                range: false,
                value: '',
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: '',
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                required: true,
                classname: '',
                errorMessage: { noData: 'This field is required', regexFail: 'Date is not valid!!' },
                errorClass: 'errorMessage',
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-GB',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: 'Initiation End  Date',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_837',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_273',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_642',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_266',
        fields: [
          {
            control: [
              {
                type: 'radio',
                name: 'iq_oq_pq_status',
                id: 'iq_oq_pq_status',
                value: { id: 1, value: 'Yes' },
                required: false,
                classname: 'form-check-wrap',
                errorMessage: { noData: '' },
                options: [
                  { key: { id: 1, value: 'Yes' }, label: 'Yes' },
                  { key: { id: 2, value: 'No' }, label: 'No' },
                ],
                readonly: false,
                validationButtonType: 'submit',
                disabled: false,
                wrapperClassName: 'form-check form-check-inline',
                showInputLabel: true,
                labelContent: 'IQ,OQ,PQ  Status  required? ',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
                controlPoints: [
                  {
                    type: 'change',
                    value: { id: 1, value: 'Yes' },
                    Mapto: [
                      {
                        id: 'main.wrapper_266.wrapper_258',
                        type: 'showField',
                      },
                      {
                        id: 'main.wrapper_266.wrapper_729',
                        type: 'showField',
                      },
                    ],
                  },
                  {
                    type: 'change',
                    value: { id: 2, value: 'No' },
                    Mapto: [
                      {
                        id: 'main.wrapper_266.wrapper_258',
                        type: 'hideField',
                      },
                      {
                        id: 'main.wrapper_266.wrapper_729',
                        type: 'hideField',
                      },
                    ],
                  },
                ],
              },
            ],
            type: '',
            name: 'wrapper_242',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_595',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'date_completion',
                id: 'date_completion',
                validationButtonType: 'submit',
                range: false,
                value: '',
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: '',
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                errorClass: 'errorMessage',
                required: true,
                classname: '',
                errorMessage: { noData: 'This field is required', regexFail: 'Date is not valid!!' },
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-GB',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: 'Date of  completion',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_968',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_258',
            fields: [],
            head: '',
            tabHeadClass: '',
            disableValue: {
              date_completion: null,
            },
          },
          {
            control: [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'storage_temp',
                id: 'storage_temp',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: { data_types: ['st_temp'] },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                connectedArrays: [],
                showInputLabel: true,
                labelContent: 'Storage  Temp(°C)',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_660',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_700',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'instrument_location',
                id: 'instrument_location',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Instrument Location',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_194',
            classname: 'col-3  mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_229',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'description',
                id: 'description',
                value: '',
                placeholder: '',
                multiline: true,
                classname: 'form-control textarea',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Description',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_319',
            classname: 'col-12',
            wrapperType: 'BOX',
            id: 'wrapper_971',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },

  //Instruments file upload
  {
    jsonId: 61,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [
          {
            type: 'dropdown',
            showBlankInPlaceholder: true,
            blankDataList: ['""', 'undefined', 'null'],
            name: 'file_type',
            id: 'file_type',
            validationButtonType: 'upload',
            classname: '',
            required: true,
            apiEndPoints: [],
            options: [
              {
                key: 'CMC',
                value: 'CMC Contract',
              },
              {
                key: 'AMC',
                value: 'AMC Contract',
              },
              {
                key: 'certificate',
                value: 'IQ/OQ/PQ Certificate',
              },
            ],
            multiple: false,
            isClearable: false,
            isStatus: false,
            search: false,
            singleSelect: true,
            showLabel: 'value',
            errorMessage: { noData: 'This field is required' },
            value: '',
            imageUrl: '',
            disabled: false,
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Select File Type',
            labelClass: 'form-label',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_801',
        classname: 'col-12 mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_766',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [
          {
            type: 'file',
            name: 'file',
            id: 'file',
            value: 'upload',
            classname: 'btn btn-file',
            fileNameShow: true,
            size: 10,
            url: '',
            functionName: '',
            wrapperClassName: '',
            fileCount: 1,
            sizeKB: '50000',
            allowMultiple: false,
            required: true,
            validationButtonType: 'upload',
            formDataType: 'blob',
            errorMsg: { invalidFormat: 'Invalid File Format', invalidSize: 'File Size exceeded' },
            fileTypes: ['pdf', 'docx', 'doc'],
            listClass: '',
            mainClass: '',
            btnDivClass: '',
            showInputLabel: true,
            labelContent: 'Drag and drop files here or browse files  ',
            labelClass: 'form-label',
            requiredErrorMessage: 'This field is required',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
            showViewIcon: false,
            showDownloadIcon: false,
          },
        ],
        type: '',
        name: 'wrapper_379',
        classname: 'col-12 mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_461',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },

  //Instruments inventory filter
  {
    jsonId: 62,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_666',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_367',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                name: 'inv_master_id',
                id: 'inv_master_id',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata/master-type-list',
                    payload: ['instruments'],
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Instrument',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_201',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_926',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'inv_item_code',
                id: 'inv_item_code',
                validationButtonType: '',
                classname: 'form-control',
                required: false,
                apiEndPoints: [],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Instrument Id',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_64511',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_99711',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                name: 'status',
                id: 'status',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [],
                options: [
                  {
                    id: 1,
                    value: 'Active',
                  },
                  {
                    id: 2,
                    value: 'Under Repair',
                  },
                  {
                    id: 3,
                    value: 'Retired',
                  },
                ],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Status',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_201',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_926',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                name: 'vendor',
                id: 'vendor',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['vendor'],
                      inv_type_perma: 'instruments',
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Vendor',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_201',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_026',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'serial_no',
                id: 'serial_no',
                validationButtonType: '',
                classname: 'form-control',
                required: false,
                apiEndPoints: [],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Serial Number',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_645',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_997',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'catalogue_no',
                id: 'catalogue_no',
                validationButtonType: '',
                classname: 'form-control',
                required: false,
                apiEndPoints: [],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Catalogue Number',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_645',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_997',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'date_purchase',
                id: 'date_purchase',
                validationButtonType: '',
                range: true,
                value: '',
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: '',
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                regularExpression:
                  '^(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/\\d{4}\\s+to\\s+(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/\\d{4}$',
                required: false,
                classname: '',
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: 'Date is not valid!!',
                },
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-GB',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: 'Date of purchase',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_749',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_285',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'date_installation',
                id: 'date_installation',
                validationButtonType: 'submit',
                range: true,
                value: '',
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: '',
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                regularExpression:
                  '^(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/\\d{4}\\s+to\\s+(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/\\d{4}$',
                required: false,
                classname: '',
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: 'Date is not valid!!',
                },
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-GB',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: 'Date of installation',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_749',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_285',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
];
